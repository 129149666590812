@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.generate-prospectus-modal
  @include displayFlex(center, $flexDirection: column)
  width: 433px
  max-width: 433px

  img, p
    margin-bottom: 16px

  &__headline
    @include font(22px, 24px)
    margin-bottom: 8px

  &__error
    @include font(16px, $red, 24px)
    text-align: center
    a
      margin-left: 5px

  .provider-modal__button
    margin-top: 24px
