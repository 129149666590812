@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.score-chart

  @property --p
  syntax: '<number>'
  inherits: true
  initial-value: 0

  .pie
    --p: 20
    --b: 10px
    --c: #D8D8D8
    --w: 478px
    width: var(--w)
    aspect-ratio: 1
    position: relative
    display: inline-grid
    place-content: center
    transform: rotate(-90deg)
    pointer-events: none
    &:not(.default)
      @include positionAbsolute(0, 0)

  .pie:before,
  .pie:after
    content: ""
    position: absolute
    border-radius: 50%

  .pie:before
    inset: 0
    background: radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p)*1%),#0000 0)
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)))
    mask: radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)))

  .pie:after
    inset: calc(50% - var(--b)/2)
    background: var(--c)
    transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2))
    height: calc(var(--b) + 0.5px)

  &__pie-wrapper
    position: relative
    height: 10px

  &__percents-info
    @include font(16px, #BEBEBE, 24px, 500)
    text-align: center
    letter-spacing: -0.32px
    margin-top: 28px
    margin-bottom: 22px

  &__percents
    @include fontSLH(48px, 32px)
    @include displayFlex(flex-end, center)
    color: $default
    letter-spacing: -0.96px
    font-weight: 500
    margin-bottom: 4px
    .score-action-icon
      @include square(20px)
      margin-left: 4px

  &__visibility-label-wrapper
    @include displayFlex(center, center)
    width: 100%

  &__visibility-label
    @include fontSLH(14px, 18px)
    font-weight: 900
    border-radius: 999px
    border: 2px solid
    padding: 0 12px
    height: 30px
    display: inline-flex
    align-items: center
    justify-content: center
    margin-bottom: 24px

  &.excellent,
  &.good
    .pie:not(.default)
      --c: #72BA16
    .score-chart__visibility-label
      color: $green
      background: rgba(119, 188, 31, 0.10)
      border-color: rgba(119, 188, 31, 0.50)

  &.medium
    .pie:not(.default)
      --c: #FFA82E
    .score-chart__visibility-label
      color: $orange
      background: rgba(252, 143, 1, 0.10)
      border-color: rgba(252, 143, 1, 0.50)
  &.low
    .pie:not(.default)
      --c: #FF5E00
    .score-chart__visibility-label
      color: #FC6A01
      background: rgba(252, 106, 1, 0.10)
      border-color: rgba(252, 106, 1, 0.50)

.score-action-icon
  @include displayFlex(center, center)
  border-radius: 999px
  position: relative
  z-index: 2

  &.animated
    @include square(0)
    animation: click-wave 0.65s
    position: absolute
    top: 0
    left: 0
    background: $green
    z-index: 1

  @keyframes click-wave
    0%
      height: 16px
      width: 16px
      opacity: 0.35
    100%
      height: 100px
      width: 100px
      margin-left: -45px
      margin-top: -45px
      opacity: 0

.score-action-tooltip
  @include displayFlex(center, center)
  @include font(14px, $orange, 16px, 500)
  height: 24px
  padding: 0 8px 0 2px
  letter-spacing: -0.28px
  border-radius: 999px
  background: #FFF8EB
  margin-left: 16px
  span
    font-weight: 900
  &.filled
    background: $white
    border: 1px solid #F1F8E9
    color: rgba(0, 0, 0, 0.60)
    strong
      font-weight: 500
    svg
      fill: white
  .score-action-icon
    margin-right: 4px


.quality-score-banner
  @include displayFlex(center)
  padding: 8px 16px 8px 8px
  border: 0.5px solid #F9A501
  background: rgba(255, 163, 0, 0.05)
  border-radius: 20px
  margin-bottom: 33px
  &.sticky
    position: fixed
    z-index: 111
    right: 24px
    top: 144px
    padding: 12px
    border-radius: 12px
    cursor: pointer
    background: #FFFAF2
    .score-action-icon
      @include square(32px)
      min-width: 32px
    .quality-score-banner__title
      margin-bottom: 0
    .quality-score-banner__info-modal-link
      margin-left: 0

  .score-action-icon
    @include square(52px)
    min-width: 52px
    margin-right: 16px

  &__title
    @include fontSLH(18px, 24px)
    margin-bottom: 8px
    font-weight: 900
    span
      font-weight: 500
      color: rgba(0, 0, 0, 0.80)

  &__description
    @include fontSLH(14px, 16px)
    color: rgba(0, 0, 0, 0.60)

  &__info-modal-link
    @include fontSLH(16px, 18px)
    @include displayFlex(center)
    cursor: pointer
    white-space: nowrap
    margin-left: 24px
    &:hover
      text-decoration: underline
    svg
      margin-left: 8px

  &__content-wrapper
    @include displayFlex(center, space-between)
    width: 100%
