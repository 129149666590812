@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.skeleton-box-wrapper
  .skeleton-box
    @include boxSize(100%, 13px)
    background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 60%) $grey-bg
    background-size: 200% 100%
    background-position-x: 180%
    animation: 0.7s loading ease-in-out infinite
    border-radius: 50px
    margin: 4px 0

  @keyframes loading
    to
      background-position-x: -20%


.pulsing-loader-wrapper
  margin-bottom: 300px
  padding-top: 300px
  .pulsing-loader
    @include square(60px)
    margin: 0 auto
    background: $green
    border-radius: 50%
    animation: boing .8s linear infinite
    position: relative
    top: calc(50% - 35px)
    &:before
      @include beforeEmptySquare(56px, $light-green, calc(50% - 28px), $left: calc(50% - 28px))
      display: block
      border-radius: 50%
      animation: boing-inner .8s linear infinite
    &:after
      @include beforeEmptySquare(52px, $white, calc(50% - 26px), $left: calc(50% - 26px))
      display: block
      border-radius: 50%
      animation: boing-inner-2 .8s linear infinite

  @keyframes boing
    0%
      transform: scale(1)
    25%
      transform: scale(.75)
    100%
      transform: scale(1)

  @keyframes boing-inner
    0%
      transform: scale(1)
    25%
      transform: scale(.6)
    100%
      transform: scale(1)

  @keyframes boing-inner-2
    0%
      transform: scale(1)
    10%
      transform: scale(.1)
    100%
      transform: scale(1)


.file-loader
  width: 100%
  display: flex
  justify-content: center
  height: 8px
  .progress
    text-align: left
    overflow: hidden
    appearance: none
    position: relative
    width: 100%
    height: 8px
    border-radius: 4px
    &::before
      content: ''
      width: 10000%
      height: 8px
      position: absolute
      background: linear-gradient(-45deg, white 33%, #77bc1f 33%, #77bc1f 66%, white 66%)
      background-size: 13px 8px
      animation: shift 300s linear infinite

  @keyframes shift
    0%
      right: 0%
    100%
      right: -9900%


.ellipsis-loader
  height: 12px
  & > div span
    width: 6px
    height: 6px
    border-radius: 50%
    background-color: $green
    display: inline-block
    margin: 0 3px
    animation: 1s scaling ease-in-out infinite
  & > div span:first-of-type
    animation-delay: .2s
  & > div span:nth-of-type(2)
    animation-delay: .4s
  & > div span:last-of-type
    animation-delay: .6s

  @keyframes scaling
    0%, 100%
      transform: scale(0)
    50%
      transform: scale(1)

