@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'

.inline-chart
  width: 100%
  height: 336px

  .custom-tooltip
    background: $white
    border: 0.5px solid $black
    border-radius: 4px
    padding: 8px
    font-size: 12px
    &__headline
      font-weight: bold

  //.recharts-wrapper
  //  //width: 100% !important
  //  svg
  //    @include boxSize(100%, auto)
