@import '../../../../../../src/stylesheets/variables'
@import '../../../../../../src/stylesheets/mixins'

.combined-kind-form
  width: 456px
  padding: 16px 12px
  position: relative
  border-radius: 6px
  border: 1px solid rgba(0, 0, 0, 0.11)
  background: $white
  &:not(:first-child)
    margin-top: 12px

  &__delete-btn
    @include positionAbsolute(12px, 12px)
    cursor: pointer
    margin-right: 0 !important
    &:hover svg
      fill: $red

  &__headline
    @include fontSLH(14px, 20px)
    font-weight: 900
    margin-bottom: 12px

  &__kind
    @include fontSLH(14px, 24px)
    margin-bottom: 12px
    div.col-lg-4:first-child
      display: flex
      justify-content: flex-end
      padding-right: 16px !important

  .range-inputs__col
    min-width: unset

  .basic-dropdown
    min-width: 227px
    .Dropdown-control
      height: 36px
    .Dropdown-placeholder
      top: -3px
      position: relative
      font-size: 14px
    .Dropdown-menu
      font-size: 14px

  .form-group
    margin-bottom: 12px !important
    &:last-child
      margin-bottom: 0 !important

  .form-label
    padding-top: 5px !important
    font-size: 14px !important

  .input-wrapper input
    height: 36px
    width: 80px

  .form-label span:last-child
    padding-right: 8px !important

  .form-group.range-inputs.error input
    border-color: $light-red

  &__error-message
    @include fontSLH(12px, 20px)
    color: $light-red
