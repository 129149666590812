@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.provider-table-wrapper
  margin-bottom: 32px

  .scroll-end-target, .sticky-start-target
    position: absolute

  .scroll-end-target
    right: 0

  .sticky-start-target
    left: 0
    height: 100%
    top: 0

  .sticky-border .sticky-start-target:before
    content: ''
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.10) 100%)
    width: 16px
    height: 100%
    display: block
    position: absolute
    left: -16px
    top: 0
    z-index: 9999999

  *
    color: $default

  .capitalize
    text-transform: capitalize

  .provider-table
    overflow-x: scroll
    position: relative

    &::-webkit-scrollbar
      -webkit-appearance: none
      height: 8px
      width: 0

    &::-webkit-scrollbar-thumb
      border: 4px solid $green
      border-radius: 4px

    &__no-results-wrapper
      @include displayFlex(center, center, column)
      padding-top: 64px
      p
        @include font(40px, $default, 48px)
        margin-top: 0
        margin-bottom: 16px

    &.no-wrap .MuiTable-root .MuiTableHead-root th
      min-width: auto
      white-space: nowrap

    .main-contact td
      background: $pale-pale-green

    &__expanded-row
      position: relative
      background: $white
      &.even
        background: #f9f9f9 !important

  td[data-sticky-td="true"]:hover
    z-index: 5 !important


    &.sticky
      overflow: scroll
      .header,
      .footer
        position: sticky
        z-index: 1
        width: fit-content
      .header
        top: 0
        box-shadow: 0 3px 3px #ccc

      .footer
        bottom: 0
        box-shadow: 0px -3px 3px #ccc

      .body
        position: relative
        z-index: 0

      [data-sticky-td]
        position: sticky

      [data-sticky-last-left-td]
        box-shadow: 2px 0 3px #ccc

      [data-sticky-first-right-td]
        box-shadow: -2px 0 3px #ccc

  .MuiTable-root
    border: none

  .MuiTable-root .MuiTableHead-root
    background: #f4f4f4
    border-bottom: none
    height: 64px
    th
      @include fontSLH(16px, 19px)
      font-weight: 500
      border-bottom: none
      padding: 8px 12px
      min-width: 120px
      background: #f4f4f4
      position: relative
      &:first-child
        padding-left: 24px
    td
      &:first-child
        padding-left: 24px

  .MuiTableCell-root
    @include fontSLH(14px, 21px)
    font-family: "MuseoSans", sans-serif
    border-bottom: 0
    padding: 8px 12px
    background: #fff
    a
      @include font(14px, #000, 21px, 500)
      text-decoration: none
      &:hover
        color: #77bc1f

    .client-info-text
      margin-bottom: 0
      a
        text-decoration: underline
        font-size: 16px

  .MuiTable-root .MuiTableBody-root .MuiTableCell-root
    &:first-child
      padding-left: 24px

  .MuiTableCell-footer
    @include font(14px, $default, 21px)
    border: none
    &:first-child
      font-weight: 900
      padding-left: 24px

  .pagination-wrapper
    height: 64px
    background: $grey-bg
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    padding: 12px 24px
    .pagination
      margin: 0 auto
      display: flex
      align-items: center
      padding-left: 0
      list-style: none
      li a
        font-size: 14px
        line-height: 16px
        font-weight: 300
        border-radius: 2px
        padding: 8px 13px
        text-decoration: none
        color: #000
        cursor: pointer
        outline: none
        display: block
        &:hover
          font-weight: 500
      .active
        a
          outline: none
          background: #77BC1F
          color: #fff
      .next, .previous
        a
          padding: 0
          display: block
      .disabled
        display: none

  .per-page-wrapper
    @include displayFlex(center)
    @include positionAbsolute(16px, 24px)

  .per-page-label
    @include fontSLH(16px, 19px)
    margin-right: 8px

  .basic-dropdown.per-page
    width: 51px
    min-width: 51px
    margin: 0
    .Dropdown-control
      height: 32px
      border-radius: 2px
      padding: 4px 22px 4px 8px
    .Dropdown-arrow-wrapper
      top: 3px
      right: 1px
    .Dropdown-placeholder
      font-size: 14px
    .Dropdown-option
      font-size: 14px

  .table-header
    @include displayFlex(center)
    cursor: pointer
    &:hover span
      text-decoration: underline
    svg
      margin-left: 4px
      &.active
        fill: $green-hover

  .with-icon
    @include displayFlex(center)

  .table-tooltip-wrapper
    display: block
    height: 21px
    margin: 0 8px
    cursor: pointer
    svg
      fill: $green
    .table-tooltip
      font-size: 14px
      max-width: 300px

  #extra-lead-tooltip
    font-size: 14px
    max-width: 300px

  .order-leases-cell
    margin-bottom: 8px
    a
      font-weight: 900 !important
      display: block
    &:last-child
      margin-bottom: 0

  .table-notes-cell
    @include displayFlex(center)
    cursor: pointer
    img
      margin-right: 4px
    p
      margin-bottom: 0
    &:hover p
      text-decoration: underline

  progress.location-score-progress
    -webkit-appearance: none
    box-sizing: border-box
    display: inline-block
    height: 1em
    width: 7em
    vertical-align: -0.2em
    border-radius: 11px

  progress.location-score-progress[value]::-webkit-progress-bar
    background-color: #CBCECF
    border-radius: 11px

  .location-score-progress::-webkit-progress-value
    background-color: $green
    border-radius: 11px

  .location-score-progress::-moz-progress-bar
    background-color: $green
    border-radius: 11px

  .MuiTableRow-root
    position: relative

  .MuiTableBody-root:not(.loading)
    .MuiTableRow-root.even
      .MuiTableCell-root
        background: #f9f9f9

.cell-dropdown-wrapper
  @include displayFlex(center)
  margin: 0.5px 0 8px
  min-height: 21px
  cursor: pointer
  &:last-child
    margin: 0

.table-button
  @include displayFlex(center, center)
  padding: 4px 8px
  background: $orange
  border-radius: 4px
  width: 131px
  cursor: pointer
  &:hover
    background: $orange-hover
  span
    @include font(12px, $white, 12px, 900)
    position: relative


.table-actions-popup
  width: 28px

  .tooltip-popup__icon svg
    fill: #9B9B9B

  .__react_component_tooltip
    padding: 5px 0 !important
    margin-top: 28px
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25)
    border-radius: 8px
    min-width: 175px
    &:before, &:after
      display: none

  .table-actions-wrapper
    display: flex
    flex-direction: column

  .table-action-link
    @include displayFlex(center)
    @include font(16px, $default, 24px)
    padding: 0 16px
    height: 44px
    white-space: nowrap
    margin-bottom: 0
    cursor: pointer
    &:hover
      background: #EDF4F3
      color: $default !important
    svg
      margin-right: 16px

.cell-dropdown
  @include font(10px, $default, 12px, 300)
  @include truncate(131px)
  user-select: none
  position: relative
  height: 20px
  border: 0.5px solid #000000
  border-radius: 4px
  padding: 4px 21px 4px 8px
  white-space: nowrap
  //&:last-child
  //  margin: 0
  svg
    @include positionAbsolute(1px, 2px)
    pointer-events: none
  &__menu
    @include font(10px, $default, 12px, 300)
    width: 131px
    position: absolute
    border: 0.5px solid #000000
    border-radius: 4px
    background: $white
    padding: 0
    overflow: hidden
    z-index: 10
  &__option
    padding: 4px 8px
    cursor: pointer
    &:hover
      background: #f4f5f5
    &.active
      background: #f4f5f5
      pointer-events: none

