@import '../../../../../src/stylesheets/variables'
@import '../../../../../src/stylesheets/mixins'


.economy-step
  &__secondary-kinds-message
    @include displayFlex(center)
    position: relative
    background: #FAFCFF
    border: 1px solid #FFA300
    border-radius: 4px
    padding: 16px 32px 16px 16px
    margin-bottom: 64px
    h3
      @include font(18px, #FFA300, 26px)
      margin-bottom: 8px
    p
      margin-bottom: 0
    svg
      position: absolute
      top: 12px
      right: 12px
      cursor: pointer

  #annual-prices, #annual-prices-m2
    transition: all ease 0.4s
    overflow: hidden
    max-height: 0
    &.uncollapsed
      max-height: 2000px

  &__expand-btn-wrapper
    @include displayFlex(center)
    cursor: pointer
    &:hover
      span
        color: $green-hover
      svg
        fill: $green-hover
    svg
      margin-left: 8px
      cursor: pointer

  &__combined-locations
    background: #FAFCF6
    padding: 16px
    display: inline-block
    margin-top: -16px
    margin-bottom: 32px
    margin-left: 62px

  &__combined-locations-add-btn
    @include font(14px, $green, 20px, 900)
    margin-top: 12px
    cursor: pointer
    text-decoration: underline
    &:hover
      color: $green-hover

  &__secondary-kinds-message-img
    margin-right: 24px

  .react-datetime-picker
    width: 100px
    min-width: 100%
    height: 40px

    &__wrapper
      @include displayFlex(center)
      border: 1px solid #e0e2e2
      border-radius: 4px
      padding: 0 16px

    &__inputGroup
      padding: 0
      input
        @include fontSLH(16px, 24px)
      &__leadingZero
        @include fontSLH(16px, 24px)

    &__calendar-button, &__clear-button
      @include displayFlex(center)
      padding: 0

    &__calendar-button:hover svg
      fill: $green

    &__clear-button
      margin-right: 8px
      &:hover svg
        fill: $red

    &__calendar, &__clock
      inset: auto auto 100% 0 !important

    &__clock
      border: 1px solid #e0e2e2
      border-radius: 4px

    .react-calendar
      border: 1px solid #e0e2e2
      border-radius: 4px

  .as-tab
    padding: 8px
    margin: -8px
    background: rgba(255, 163, 0, 0.1)
    border-radius: 4px 4px 0px 0px

  .secondary-kinds
    max-width: 437px !important
    h3
      color: $orange
      margin-bottom: 8px

  .location-form__section-wrapper
    margin-bottom: 16px

  .step-header
    @include displayFlex(center)

  .score-action-tooltip
    margin-left: 16px

  .sectionScrollTarget
    position: relative
    top: -120px
