@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.active-sessions
  &__headline
    @include font(16px, $default, 20px, bold)
    padding-bottom: 8px
    width: 100%
    border-bottom: 2px solid #EDEDED
    margin-bottom: 16px

  &__wrapper
    display: inline-flex
    flex-direction: column

  &__sub-header
    @include font(16px, $orange, 20px, bold)
    margin-bottom: 16px

  &__sessions-list
    display: inline-block
    margin-bottom: 8px
    &.other-sessions
      margin-bottom: 0
      .active-sessions__session:last-child .active-sessions__session-bottom
        margin-bottom: 0

  &__session
    @include fontSLH(16px, 20px)

  &__session-top
    @include displayFlex(center)
    position: relative
    margin-bottom: 8px

  &__time-info
    @include displayFlex(center)
    @include positionAbsolute(-2px, 0)
    height: 24px
    svg
      fill: #EA4335
      cursor: pointer

  &__time
    margin-right: 3px
    &.current
      margin-right: 27px

  &__browser-info
    padding-right: 122px

  &__session-bottom
    font-weight: 100
    margin-bottom: 16px

