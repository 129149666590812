@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'

.notes-wrapper
  margin-top: 32px
  scroll-behavior: smooth
  &__main-title
    font-family: MuseoSans, sans-serif
    @include fontSLH(20px, 20px)
    border-bottom: 2px solid #EDEDED
    padding-bottom: 8px
    span
      font-weight: bold

  textarea
    height: 110px
    width: 100%
    border: 1px solid $borders
    padding: 10px
    border-radius: 4px

  &__add-notes-button
    height: 40px
    margin-top: 8px
    width: 100%
    background: $green
    border: none
    border-radius: 4px
    color: $white
    cursor: pointer
    font-size: 16px
    font-weight: bold
    margin-bottom: 24px
    &:hover
      background: $green-hover

  &__notes
    @include fontSLH(14px, 24px)
    border: 1px solid #EDEDED
    border-radius: 4px
    margin-bottom: 8px
    padding: 8px

    .notes-edit-block
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 8px
      .edit
        cursor: pointer
        &:hover
          fill: $green
      .delete
        cursor: pointer
        &:hover
          fill: $red

    .notes-author
      font-weight: bold

    .notes-date
      font-weight: bold

    .notes-text
      font-size: 14px
      margin-bottom: 8px


.notes-wrapper-modal
  padding: 20px
