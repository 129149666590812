@import '../../../../../src/stylesheets/variables'
@import '../../../../../src/stylesheets/mixins'


.input-banner
  @include displayFlex(center, space-between)
  width: 100%
  min-width: 328px
  padding: 16px 24px
  margin-top: 16px
  background: rgba(255, 163, 0, 0.05)
  border: 1px solid $orange
  border-radius: 4px
  margin-bottom: 16px

  &.green
    border-color: $green
    background: #F2FFE2

  &__headline
    @include fontSLH(16px, 20px)
    margin-bottom: 8px
    font-weight: bold

  &__buttons
    margin-left: 40px

  &__read-more
    @include font(16px, $orange, 19px, bold)
    text-decoration: underline
    &:hover
      color: $orange-hover

  &__buy-button
    @include font(16px, $white, 19px, bold)
    height: 40px
    padding: 0 24px
    border-radius: 4px
    background-color: $orange
    text-decoration: none
    &:hover
      background: $orange-hover
      color: $white

  &__order-service-button
    min-width: 178px

  &__content,
  &__buttons-separator
    @include fontSLH(14px, 21px)

  &__buttons,
  &__buy-button
    @include displayFlex(center, center)

  &__read-more,
  &__buy-button
    cursor: pointer
    white-space: nowrap

  &__read-more,
  &__buttons-separator
    margin-right: 24px


