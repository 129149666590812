.form-notification-block
  display: inline-block
  margin-bottom: 24px
  padding: 8px 25px
  color: #fff
  border-radius: 4px

.green
  background: #77bc1f

.red
  background: #FF000066
