@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.provider-settings
  padding: 32px

  h3
    border-bottom: 2px solid #EDEDED
    margin-bottom: 16px
    padding-bottom: 8px

  &__contact-name
    font-weight: 900
    text-transform: uppercase

  &__checkbox-wrapper
    font-size: 16px
    margin-bottom: 10px
    position: relative
    user-select: none
    .checkbox-label
      cursor: pointer
      margin-left: 15px
      display: inline-block
      &:hover
        .checkmark
          border: 1px solid $green
    input
      height: 16px
      width: 1px
      position: absolute
      left: 3px
      top: 5px
      border: none
      padding: 0
      &:checked ~ .checkmark
        background-color: $green
        border-color: $green

    .checkmark
      @include square(20px)
      @include positionAbsolute(0, $left: 0)
      position: absolute
      border: 1px solid $inactive
      border-radius: 2px
      background: $white
      svg
        @include positionAbsolute(0, $left: 0)
        fill: $white

    .checkbox-label-wrapper
      @include fontSLH(16px, 24px)
      margin-left: 15px
      cursor: pointer
      user-select: none
      label
        display: inline-block
        cursor: pointer
        margin-left: 20px
      &:hover
        .checkmark
          border: 1px solid $green


  &__language
    margin: 32px 0


  &__save-button
    display: block
    margin-top: 40px
    font-size: 16px
    color: #fff
    line-height: 40px
    font-weight: bold
    background: $green
    border: none
    border-radius: 4px
    padding: 0 30px
    cursor: pointer
    text-transform: capitalize
    &:hover
      background: $green-hover

  .second-contact-message
    span
      color: #FF0000

  .second-contact-logo-wrap
    margin-bottom: 24px
    img
      height: 80px
      max-width: 160px

  .table-tooltip-wrapper
    @include positionAbsolute(16px, 18px)
    z-index: 1
