@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.base-modal
  @include displayFlex(center, $flexDirection: column)
  width: 433px
  max-width: 433px
  line-height: 24px
  text-align: center

  p
    margin-bottom: 24px

  &__buttons-wrapper
    @include displayFlex(stretch, space-between)
    width: 100%

  &__confirm-button, &__reject-button
    @include displayFlex(center, center)
    @include font(16px, $white, 19px, 900)
    padding: 8px 24px
    min-width: 170px
    border-radius: 4px
    cursor: pointer

  &__confirm-button
    background: $green
    &:hover
      background: $green-hover

  &__reject-button
    background: $light-red
    margin-right: 24px
    &:hover
      background: $red
