@import '../../../../../src/stylesheets/variables'
@import '../../../../../src/stylesheets/mixins'

.visuals-block
  border-radius: 16px
  border: 1px solid #E6E6E6
  margin-bottom: 16px
  padding: 24px 24px 8px

  &__wrapper
    @include displayFlex(flex-start, space-between)

  &__input-wrapper
    @include displayFlex(center)
    flex-wrap: wrap

  &__input-submit-btn
    @include font(14px, $green, 24px, 900)
    justify-content: center
    padding: 0 32px
    background: transparent
    cursor: pointer
    pointer-events: none
    border: 2px solid $green
    white-space: nowrap
    &.active
      pointer-events: all
      color: $white
      background: $green
      &:hover
        border-color: $green-hover
        background: $green-hover

  .input-wrapper
    min-width: 352px
    margin-right: 24px

  &__input-headline
    @include font(18px, $default, 26px, bold)
    margin-bottom: 8px
    span
      margin-left: 8px

  &__input-label
    @include fontSLH(16px, 24px)
    margin-bottom: 16px
    p
      margin-bottom: 0

  .form-group--visuals
    flex-direction: column
    margin-right: 16px
    position: relative
    margin-bottom: 0 !important
    &.file
      width: 100%
      max-width: 100%

  &__wrapper,
  &__input-submit-btn
    border-radius: 4px

  input,
  &__input-submit-btn
    height: 40px
    margin-bottom: 16px

  input,
  .input-wrapper
    width: 100%
    max-width: 352px

  &__input-wrapper,
  &__input-headline,
  &__input-submit-btn
    @include displayFlex(center)
