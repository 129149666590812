@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.main-dashboard
  flex: 1 0 auto

  .create-new-button.alone
    margin-left: 48px
    margin-bottom: 48px

  &__top-block
    @include displayFlex(center, space-between)
    padding: 24px 48px 48px
    img
      margin-left: 162px

  &__top-text
    p
      @include fontSLH(36px, 43px)
      margin-bottom: 8px
      span
        font-weight: 900
        &:last-child
          margin-left: -10px
      &:last-child
        @include fontSLH(28px, 34px)

  .contacts-table
    &__button-wrapper
      p
        cursor: default
