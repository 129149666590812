@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.duplicate-location-modal
  @include displayFlex(center, $flexDirection: column)
  width: 433px
  max-width: 433px
  line-height: 24px
  text-align: center

  &__headline
    font-size: 22px
    font-weight: 900

  &__explanation
    text-align: center
    margin-bottom: 40px
    &.error
      font-size: 14px
      color: $red

  &__loading-subheader
    margin-bottom: 8px

  &__loading-explanation
    font-size: 14px
    margin-bottom: 32px

  &__loading-subheader,
  &__explanation
    font-size: 16px

  img,
  &__headline,
  .file-loader
    margin-bottom: 16px
