@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.statistics
  padding: 48px

  .MuiTableHead-root th:last-child
    padding-right: 24px

  .MuiTableRow-root
    z-index: 2
    // For Safari to prevent expanded content overlap
    -webkit-transform: translate3d(0,0,0)
    td:last-child
      padding-right: 24px
    &.MuiTableRow-footer td
      text-align: right
      &:first-child
        text-align: left
      &:last-child
        padding-right: 24px

  .align-right
    text-align: right
    //white-space: nowrap

  &__chart-block
    width: 321px
    .location-views-chart
      .__react_component_tooltip
        &:before, &:after
          display: none

  .inline-chart
    height: 213px
    tspan
      font-size: 11px

  .provider-table__expanded-row
    height: 0
    transition: height linear 0.6s
    z-index: 0
    &.expanded
      height: 270px
    td
      background: $white
      transition-delay: 0.6s
      transition-property: background
      &.expanded
        background: transparent
        transition-delay: 0s

  &__address-cell
    @include displayFlex(center)
    svg
      margin-right: 16px
      min-width: 18px
      cursor: pointer
      &:hover
        fill: $green

  &__expanded-data
    position: absolute
    padding-bottom: 24px
    padding-top: 8px
    h3
      line-height: 24px
      margin-bottom: 0

    .location-views-chart__days-switchers
      position: absolute
      right: 16px
      top: 9px

  &__expanded-data-row
    @include displayFlex(flex-end)

  &__expanded-sticky-wrapper
    position: sticky
    left: 24px
    display: initial

  &__skeleton-loader
    display: none
    width: 50px
    margin-left: 5px
    position: relative
    top: 2px
    .skeleton-box
      margin: 0 !important

  &__expanded-boxes
    @include displayFlex(center)
    margin-left: 16px
    &.loading
      .statistics__skeleton-loader
        display: inline-flex
      strong
        display: none

  &__expanded-boxes-column
    display: flex
    flex-direction: column

  &__expanded-box
    @include boxSize(314px, 98px)
    @include displayFlex(center)
    padding: 5px 16px
    border: 1px solid #D8D8D8
    border-radius: 4px
    margin: 16px 16px 0 0
    background: $white
    svg
      margin-right: 16px
    span
      @include fontSLH(14px, 21px)
      display: block
      margin-bottom: 4px
      &:last-child
        margin-bottom: 0

  &__expanded-box-headline
    @include fontSLH(16px, 24px)
    font-weight: 900
    margin-bottom: 8px

  &__expanded-box-info span:last-child
    margin-bottom: 0

  &__filters-wrapper
    @include displayFlex(center, space-between)
    padding-bottom: 24px
    border-bottom: 2px solid #EDEDED
    margin-bottom: 24px
    flex-wrap: wrap

  &__csv-link
    margin: 9.5px 0
    white-space: nowrap
    display: flex
    align-items: center
    a
      margin: 0 5px

  &__filters
    display: flex

  .basic-dropdown
    margin-right: 8px
    &.kind-select
      width: 165px
      .Dropdown-placeholder, .Dropdown-option
        font-size: 14px
    .Dropdown-placeholder
      color: $dark-borders
    .Dropdown-menu .selected
      background: $border-light-grey

  .react-datetime-picker
    width: auto
    min-width: 165px
    height: 40px
    margin-right: 8px

    &.empty
      .react-datetime-picker__inputGroup__divider,
      .react-datetime-picker__inputGroup__month,
      .react-datetime-picker__inputGroup__year
        display: none
      .react-datetime-picker__inputGroup__day::placeholder
        color: $default

    &__wrapper
      padding: 0 16px

    &__inputGroup
      margin-right: 8px

      &__leadingZero,
      input
        @include fontSLH(14px, 24px)

    &__calendar-button:hover svg
      fill: $green-hover

    &__clear-button
      display: none

    &__clock,
    &__calendar
      z-index: 3

    &__wrapper,
    &__clock,
    .react-calendar
      border: 1px solid $borders
      border-radius: 4px

    &__inputGroup,
    &__calendar-button
      padding: 0

    &__wrapper,
    &__calendar-button
      @include displayFlex(center)


  .search-input-wrapper
    position: relative
    svg
      @include positionAbsolute(calc(50% - 8px), $left: 16px)

  .search-input
    @include fontSLH(14px, 24px)
    width: 295px
    padding-left: 40px
    padding-right: 16px
    height: 40px
    border: 1px solid #E0E2E2
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    margin-right: 8px

  &__file-buttons-wrapper
    @include displayFlex(center)

  .tokenized-file-link-modal
    &__link
      @include displayFlex(center, center)
      height: 40px
      border-radius: 3.5px
      border: 1px solid $green
      text-decoration: none
      padding: 0 16px
      margin-left: 8px
      svg
        fill: $green
        margin-right: 8px
      &:hover
        border-color: $green-hover
        svg
          fill: $green-hover

    &:first-child .tokenized-file-link-modal__link
      margin-left: 0
