// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.table-page
  padding: 48px
  &.preview
    padding-top: 0
    padding-bottom: 40px

  &__filters-left
    display: flex

  &__title-wrapper
    margin-bottom: 32px
    h3
      @include fontSLH(20px, 20px)
      margin-bottom: 0

  &__see-all-link
    @include font(16px, $orange, 20px)
    position: absolute
    right: 0
    text-transform: capitalize
    &:hover
      color: $orange-hover

  &__filters-wrapper
    flex-wrap: wrap
    padding-bottom: 8px
    margin-bottom: 24px
    border-bottom: 2px solid #EDEDED

    .Dropdown-control,
    .Dropdown-menu,
    .searchable-dropdown__trigger-label
      font-size: 14px

    .Dropdown-menu .selected
      background: $border-light-grey

    .Dropdown-placeholder
      color: $dark-borders

  .radio-buttons, .search-input-wrapper, .basic-dropdown
    margin-bottom: 16px

  .locations-search-select__option--is-focused
    background: $white
    &:hover, &.locations-search-select__option--is-selected
      background: $border-light-grey

  .search-input-wrapper svg
    @include positionAbsolute(calc(50% - 8px), $left: 16px)

  .search-input
    @include fontSLH(14px, 24px)
    width: 295px
    padding-left: 40px
    padding-right: 16px
    height: 40px
    border: 1px solid #E0E2E2
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none

  .basic-dropdown
    min-width: 160px

  .radio-buttons__label
    font-weight: 500
    white-space: nowrap

  .radio-buttons__icon
    top: unset

  .Dropdown-root,
  &__title
    width: 100%

  &__title-wrapper,
  .search-input-wrapper
    position: relative

  &__filters-wrapper,
  .basic-dropdown
    display: flex

  .radio-buttons__label
    font-size: 16px

  .search-input,
  .basic-dropdown
    margin-right: 16px

  &__title,
  &__title-wrapper
    @include displayFlex(center)

