@import '../../../src/stylesheets/mixins'

.contacts-table

  &.preview
    .contacts-table__tabs
      p
        &:last-of-type
          display: none
        &.active
          padding: 0
          &:after
            display: none

  &__button-wrapper
    display: flex
    margin-bottom: 32px
    justify-content: space-between
    position: relative
    align-items: center
    p
      margin-bottom: 0
      padding: 0 25px
      cursor: pointer
      @include font(20px, #000, 30px, 500)
    p.active
      position: relative
      @include font(20px, #000, 30px, 900)
      &:after
        content: ""
        height: 4px
        width: 100%
        background: #77BC1F
        display: block
        position: absolute
        left: 0
        border-radius: 4px
        bottom: -3px

  &__new-contact-button
    border: 1px solid #77BC1F
    border-radius: 16px
    padding: 7px 16px
    cursor: pointer
    margin-left: 24px
    &:hover
      background: #77BC1F
      p
        color: #fff
    p
      @include font(16px, #77BC1F, 20px, 900)
      padding: 0
  &__tabs
    display: flex

  &__active-button
    margin-bottom: 0
    cursor: pointer
    display: block
    &:hover
      text-decoration: underline
      color: #77BC1F

  &__delete-forever-button
    white-space: nowrap
    text-decoration: underline
    margin: 0
    cursor: pointer
    padding: 2px 0
    &:hover
      color: red


.action-dropdown
  height: 24px
  width: 24px
  position: relative
  .action-button
    cursor: pointer
    &:hover
      color: red

  &__menu
    position: absolute
    background: #fff
    border-radius: 4px
    box-shadow: 0 6px 15px 0 rgba(0,4,10,0.15)
    padding: 5px 0
    min-width: 175px
    z-index: 3
    p
      margin-bottom: 0
      cursor: pointer
      padding: 10px 20px
      &:hover
        background: #EDF4F3
        border-radius: 4px

      span
        margin-right: 18px
    svg
      float: left

.show-dropdown
  .action-dropdown__menu
    display: block
