@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.location-info
  padding: 24px 48px
  margin: 0 auto
  max-width: 1122px

  .sectionScrollTarget
    position: relative
    top: -120px

  .location-views-chart__top
    margin-bottom: 16px

  &__share-button-wrap
    display: flex
    border: 1px solid $dark-borders
    border-radius: 48px
    width: 176px
    height: 40px
    margin-left: auto
    gap: 16px
    margin-top: 32px
    .share-icon
      margin-left: -1px

  &__pending-approval
    color: $orange

  &__charts-wrapper
    @include displayFlex(stretch)

  &__chart-block
    border: 1px solid #D8D8D8
    padding: 16px
    position: relative
    min-width: calc(50% - 8px)
    border-radius: 4px
    &.score
      padding-bottom: 2px

  &__chart-score-item
    @include displayFlex(flex-start)
    margin-bottom: 12px
    svg
      fill: $borders
    span
      @include fontSLH(16px, 20px)
      white-space: nowrap
      color: $borders
    &.active
      svg
        color: $green
        fill: $green
      span
        color: $black

  &__main-info-wrapper
    margin: 24px 0 0

  &__main-info-headline-block
    @include displayFlex(center, space-between)
    svg
      margin-right: 8px
    a
      @include font(16px, $orange, 24px, 300)
      svg
        fill: $orange
      &:hover
        color: $orange-hover
        svg
          fill: $orange-hover

      &.location-url
        text-decoration: none
        span
          text-decoration: underline


  &__main-info-headline
    text-transform: capitalize

  &__address
    @include fontSLH(20px, 24px)
    padding-bottom: 16px
    margin-bottom: 16px
    border-bottom: 1px solid #E0E2E2

  &__status-label
    height: 28px
    border-radius: 4px
    border: 1px solid
    padding: 0 12px
    margin-left: 8px

  &__status-dot
    @include square(8px)
    margin-right: 6px
    border-radius: 999px

  &__status
    span
      @include font(16px, $default, 24px, 900)
      margin: 0 8px
    &.active
      .location-info__status-label
        color: $green
        border-color: #83B46C
      .location-info__status-dot
        background: $green
    &.unverified
      .location-info__status-label
        color: $orange-hover
        border-color: $orange
      .location-info__status-dot
        background: $orange-hover
    &.unfinished
      .location-info__status-label
        color: #898989
        border-color: #C6C6C6
      .location-info__status-dot
        background: #898989
    &.closed
      .location-info__status-label
        color: #FF1A1A
        border-color: #E2B7B7
      .location-info__status-dot
        background: #FF1A1A
    &.activated
      .location-info__status-label, .location-info__status-dot
        animation: blink 1s linear infinite

  &__status-icon
    @include displayFlex(center)
    line-height: 24px

  &__area
    svg
      margin: 0 16px 0 4px

  &__prices
    padding-bottom: 8px
    margin-bottom: 16px
    border-bottom: 1px solid #E0E2E2

  &__visuals-info-wrapper
    flex-wrap: wrap
    padding-bottom: 16px
    margin-bottom: 16px
    border-bottom: 1px solid #E0E2E2

  &__prices-label
    margin-right: 48px
    svg
      margin-right: 4px

  &__manage-buttons
    @include displayFlex(stretch)
    margin-bottom: 40px

  &__manage-button
    @include font(18px, $white, 24px, 900)
    cursor: pointer
    border-radius: 8px
    background: $orange
    padding: 8px 24px
    text-decoration: none
    text-align: center
    &:last-child
      margin-right: 0
    &:hover
      color: $white
      background: $orange-hover

    &--green
      background: $green
      &:hover
        background: $green-hover
      &.disabled
        background: $green-hover
        pointer-events: none

    img, svg
      margin-right: 8px

    .loader
      position: relative

  &__chart-headline
    user-select: none
    justify-content: space-between

  .score-chart
    &__percents
      @include fontSLH(64px, 80%)
      letter-spacing: -1.28px
      margin-bottom: 8px
    &__percents-info
      margin-bottom: 16px
      font-size: 20px
      margin-top: 54px

  .pie-chart
    max-width: 328px

  .inline-chart
    max-width: 472px
    margin: auto

  &__score-read-more
    @include fontSLH(12px, 20px)
    letter-spacing: -0.24px
    color: rgba(0, 0, 0, 0.60)
    font-weight: 500
    svg
      fill: rgba(0, 0, 0, 0.60)
      margin-left: 4px
    &:hover
      color: $default
      svg
        fill: $default

  &__score-widget
    display: flex
    justify-content: center

  &__chart-score-list
    columns: 2
    column-gap: 32px

  &__score-list-item
    @include displayFlex(center, space-between)
    height: 22px
    cursor: pointer
    margin-bottom: 14px
    text-decoration: none
    &:hover .location-info__score-list-item-name
      text-decoration: underline

  &__score-list-item-score
    @include font(14px, $orange, 16px, 900)
    display: flex
    border-radius: 999px
    background: #FFF8EB
    height: 20px
    margin-left: 8px
    padding: 0 8px
    text-decoration: none !important
    .score-action-icon
      @include square(16px)
      margin-right: 4px
      svg
        fill: $white

  &__score-list-item-name
    @include fontSLH(15px, 22px)
    color: $default
    letter-spacing: -0.3px

  &__score-list-item-label
    &.done
      .location-info__score-list-item-name
        color: rgba(0, 0, 0, 0.60)
      .location-info__score-list-item-score
        background: $white
        height: 22px
        border: 1px solid #F4F6FA
        padding: 0 3px
        color: rgba(0, 0, 0, 0.60)
        font-weight: 500

  &__quality-score-info-wrapper
    @include displayFlex(flex-start, space-between)
    padding-bottom: 16px
    margin-bottom: 16px
    border-bottom: 1px solid #E0E2E2

  &__quality-score-state
    margin-bottom: 8px
    &.excellent,
    &.good
      strong
        color: $green
    &.medium
      strong
        color: $orange
    &.low
      strong
        color: #FC6A01

  &__quality-score-points
    .score-action-icon
      @include square(24px)
      margin-right: 5px

  &__visuals-info.prospectuses
    text-transform: capitalize

  &__manage-button
    @include displayFlex(center, center)

  &__chart-headline
    margin-bottom: 16px

  &__main-info-wrapper,
  .recharts-wrapper
    display: flex

  &__enquiries-list
    flex-wrap: wrap
    margin-bottom: 24px

  &__visuals-info,
  &__inquiry
    span
      margin-left: 5px

  &__chart-block:first-child,
  &__manage-button,
  .pie-chart
    margin-right: 16px

  &__area,
  &__prices-label
    font-weight: 300

  &__main-info-headline,
  &__chart-headline
    @include font(18px, $default, 24px, 900)

  &__main-info-block,
  &__chart-wrapper,
  &__manage-button
    width: 100%

  &__main-info-block,
  &__visuals-info,
  &__inquiry
    margin-right: 24px

  &__area span,
  &__visuals-info span,
  &__inquiry span,
  &__prices-value,
  &__pending-approval
    font-weight: 900

  &__area,
  &__status,
  &__prices,
  &__visuals-info,
  &__inquiry,
  &__pending-approval,
  &__quality-score-state,
  &__quality-score-points
    @include fontSLH(16px, 24px)

  &__area,
  &__status,
  &__prices,
  &__prices-label,
  &__visuals-info-wrapper,
  &__enquiries-list,
  &__chart-headline,
  &__score-list-item-label,
  &__score-read-more,
  &__score-list-item-score,
  &__quality-score-points,
  &__status-label,
  &__main-info-headline-block a
    @include displayFlex(center)

  &__main-info-headline-block,
  &__status,
  &__area,
  &__prices-label,
  &__prices-value,
  &__inquiry,
  &__visuals-info
    margin-bottom: 8px

@keyframes blink
  0%
   opacity: 0
  25%
    opacity: .5
  50%
    opacity: 1
  75%
    opacity: .5
  100%
    opacity: 0

