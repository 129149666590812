@import '../../../../../src/stylesheets/variables'
@import '../../../../../src/stylesheets/mixins'

.location
  padding: 24px
  border-radius: 16px
  border: 1px solid #E6E6E6

  &__suggestion-link
    @include fontSLH(16px, 24px)
    @include displayFlex(center)
    cursor: pointer
    margin-top: -12px
    margin-left: 8px
    &:hover
      color: $green-hover
      svg
        fill: $green-hover
    svg
      padding-top: 3px

  &__suggestion-link-text
    text-decoration: underline
    margin-left: 5px

  &__content-wrapper
    display: flex
    position: relative
    align-items: stretch

  &__content
    .error-tooltip
      @include fontSLH(12px, 20px)
      color: $light-red
      font-weight: 300
      margin-top: 8px
    .form-group
      flex-direction: column
      margin-bottom: 16px !important
    .form-label
      text-align: left !important
      justify-content: flex-start !important
      margin-bottom: 4px
      padding-top: 0 !important
      white-space: nowrap
      &.address
        margin-left: 8px
        span
          margin-right: 8px
    .searchable-dropdown,
    .basic-dropdown,
    .input-wrapper
      min-width: 344px
      .error-tooltip
        margin-top: 0
    .no-padding
      margin-bottom: 0 !important


  &__map-text
    @include font(16px, $orange, 24px)
    margin-bottom: -16px

  &__places
    position: relative
    min-width: 344px

  &__street-placeholder
    @include fontSLH(16px, 21px)
    position: absolute
    top: 9px
    color: $dark-borders
    display: inline-flex

  .form-group__tooltip p
    margin-bottom: 0

  #autocomplete-input
    @include fontSLH(16px !important, 21px)
    width: 100% !important
    opacity: 1 !important
    line-height: 24px
    color: $default !important
    position: relative
    &:focus
      outline: none

  .gm-style-iw.gm-style-iw-c
    top: -8px
    button.gm-ui-hover-effect
      display: none !important

  .gm-style .gm-style-iw-tc::after
    top: -8px

  .navigation-buttons
    justify-content: flex-end

  .places
    &:focus-visible
      outline: unset

    &__value-container div
      display: block !important

    &__control
      cursor: text
      background: #F6F7F7
      min-height: 40px !important
      max-height: 40px !important
      border: 1px solid #E6E8E8
      & ~ div, & > div:first-of-type
        display: flex
        flex-wrap: nowrap
      &--is-focused
        border-color: $borders
        box-shadow: none

    &__input
      margin: 0 8px 0 6px

    &__placeholder
      color: $dark-borders
      margin-left: 8px
      margin-right: 16px
      white-space: nowrap
      text-overflow: ellipsis

    &__single-value
      pointer-events: none
      margin: 0 8px
      color: $default

    &__menu
      margin: 0

    &__value-container > div
      &:nth-child(1),
      &:nth-child(2)
        width: 100%

    &__menu-notice
      text-align: center

    &__input,
    &__placeholder
      overflow: hidden

    &__placeholder,
    &__single-value
      width: calc(100% - 32px) !important
      font-size: 16px

    &__menu-list,
    &__menu-notice
      width: 100%

    &__indicator-separator,
    &__dropdown-indicator,
    &__loading-indicator
      display: none
