@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.report-success-modal
  @include displayFlex(center, $flexDirection: column)
  max-width: 433px
  padding: 32px
  text-align: center

  h2
    @include fontSLH(22px, 24px)

  &__thank-you-text
    margin-bottom: 24px

  &__review-text
    @include fontSLH(14px, 24px)
    margin-bottom: 8px

  &__dashboard-btn
    @include font(16px, $white, 24px, 900)
    display: block
    margin-top: 24px
    padding: 8px 30px
    background: $green
    border: none
    border-radius: 4px
    cursor: pointer
    text-decoration: none
    &:hover
      background: $green-hover
      color: $white

  &__logo-link, h2
    margin-top: 16px

  &__logo-image
    height: 42px

