@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'


.range-inputs
  &__wrapper
    @include displayFlex(center, space-between)
    max-width: 248px
    width: 248px

  &__input-wrapper
    position: relative

  &__required-wrapper
    position: relative
    display: block
    span
      @include positionAbsolute(0, $left: 8px)

  &__units
    margin-left: 8px
    line-height: 22px
    display: block
    padding-top: 8px
    &.top
      padding-top: 0

  &__block, &__col
    flex-direction: column

  &__col
    min-width: 320px

  &__col-wrapper,
  &__input-wrapper,
  &__block
    display: flex

  .input-wrapper
    max-width: 100px

  .error-tooltip
    @include fontSLH(12px, 20px)
    color: $light-red
    font-weight: 300
    margin-top: 8px

  .input-wrapper input.error
    margin-bottom: 0

  .required-icon
    display: none

  .wide
    min-width: unset
    .input-wrapper
      max-width: 100%
    .range-inputs__input-wrapper
      display: block
      width: 100%
    .range-inputs__wrapper
      width: 100%
      max-width: 100%

