@import "../../../../src/stylesheets/variables"
@import "../../../../src/stylesheets/mixins"

.radio-buttons
  @include displayFlex(center)

  &__button-wrapper
    @include displayFlex(center)
    position: relative
    margin-right: 24px
    cursor: pointer
    &.checked
      .radio-buttons__icon
        border-color: $green
      .radio-buttons__icon span
        @include square(10px)
        background: $green
        border-radius: 50%
    &:hover
      .radio-buttons__icon
        border-color: $green
      .radio-buttons__label
        font-size: 16px
        font-weight: 500

  &__icon
    @include displayFlex(center, center)
    @include square(20px)
    @include positionAbsolute(0, $left: 0)
    border: 2px solid $gull-grey-text
    border-radius: 50%
    background: white
    pointer-events: none
    cursor: pointer

  input
    margin: 3.5px 11.5px 3.5px 3.5px
    cursor: pointer

  &__label
    @include font(16px, $default, 20px)
    @include displayFlex(center)
    cursor: pointer
