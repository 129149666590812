@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.modal-network-error
  @include displayFlex(center)
  width: 100%
  background: rgba(255, 90, 90, 0.09)
  border-radius: 4px
  padding: 16px
  margin-bottom: 16px

  svg
    margin-right: 18px

  &__message
    @include fontSLH(16px, 24px)
    text-align: left

