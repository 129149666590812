.contact-settings
  padding: 32px
  h3
    border-bottom: 2px solid #EDEDED
    margin-bottom: 16px

  &__delete-button
    display: block
    font-size: 16px
    color: #FF0000
    line-height: 40px
    font-weight: bold
    border: 1px solid #FF0000
    background: #fff
    border-radius: 4px
    padding: 0 30px
    cursor: pointer
    &:hover
      color: #fff
      background: #FF0000