@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.provider-modal
  padding: 32px

  &__wrapper
    @include displayFlex(center, $flexDirection: column)

  &__close-btn
    @include positionAbsolute(-40px, -40px)
    @include square(40px)
    @include displayFlex(center, center)
    cursor: pointer
    background: $white
    border-radius: 50%

  &__button
    @include font(14px, $white, $fontWeight: 900)
    @include displayFlex(center, $justifyContent: center)
    background: $orange
    padding: 8px 24px
    min-width: 157px
    cursor: pointer
    border-radius: 4px
    text-decoration: none
    &:hover, &.inactive
      background: $orange-hover
    &:hover
      color: $white
    &.inactive
      pointer-events: none
    &.green
      background: $green
      &:hover, &.inactive
        background: $green-hover
    &.green-border
      border: 1px solid $green
      color: $green
      background: $white
      &:hover, &.inactive
        color: $green-hover
        border-color: $green-hover
        background: $white
    &.red
      background: #FF0000
      &:hover
        background: #CB0000


  .file-loader
    max-width: 208px


