@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.welcome
  margin-top: 42px
  text-align: center
  &__title
    margin: 16px 0
    @include font(40px, #313C3F, 40px)
  &__subtitle
    margin-bottom: 8px
    @include font(24px, #313C3F, 29px)

  &__description
    margin-bottom: 24px
    @include font(16px, #9B9B9B, 19px)
  &__buttons-wrapper
    margin-top: 24px
    display: flex
    align-items: center
    justify-content: center
  &__button
    display: inline-flex
    align-items: center
    font-size: 14px
    line-height: 20px
    font-weight: bold
    text-decoration: none
    //border: 1px solid #77bc1f
    height: 40px
    border-radius: 23px
    padding: 7px 16px
  .create-new-lease
    border: 1px solid #77bc1f
    margin-right: 24px
  .edit-information
    border: 1px solid #FFA300
    color: #FFA300
    &:hover
      border: 1px solid #d17c0a
      color: #d17c0a

