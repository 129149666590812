@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.extra-lead-modal
  @include displayFlex(flex-start, center, column)
  max-width: 449px
  min-width: 449px
  padding: 32px
  line-height: 24px

  &__img-wrapper
    height: 242px
    width: 385px
    background-repeat: no-repeat
    background-size: 100% auto
    background-position: center
    border-radius: 4px 4px 0 0

  &__img-wrapper,
  &__title,
  &__address
    margin-bottom: 8px

  &__title,
  &__address,
  &__location-param
    font-size: 16px

  &__title
    font-size: 20px
    font-weight: 900

  &__location-param
    @include displayFlex(center, space-between)
    margin-bottom: 4px
    width: 100%
    div
      @include displayFlex(center)
      &:first-of-type
        margin-right: 16px
      svg
        margin-right: 8px
      &:nth-child(2)
        white-space: nowrap

  a
    @include displayFlex(center, center)
    @include font(14px, $white, 24px, 900)
    padding: 8px 30px
    background: $green
    text-decoration: none
    border-radius: 4px
    align-self: center
    margin-top: 28px
    &:hover
      background: $green-hover
      color: $white

  &__loader
    width: 100%
    .skeleton-box
      height: 24px
      &:nth-child(1), &:nth-child(2), &:nth-child(3)
        margin-bottom: 8px
      &:nth-child(4), &:nth-child(5)
        margin-bottom: 4px
      &:nth-child(7)
        margin-top: 28px
        height: 40px
      &:nth-child(1)
        height: 242px
        border-radius: 4px 4px 0 0
