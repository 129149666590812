@import "../../../../src/stylesheets/variables"
@import "../../../../src/stylesheets/mixins"

.form-group--checkbox
  display: flex
  align-items: center
  flex-direction: row
  position: relative
  &:before
    display: none

  input
    height: 16px
    width: 1px
    position: absolute
    left: 3px
    top: 5px
    border: none
    padding: 0
    &:checked ~ .checkmark
      background-color: $green
      border-color: $green

  .checkmark
    @include square(20px)
    @include positionAbsolute(0, $left: 0)
    position: absolute
    border: 1px solid $inactive
    border-radius: 2px
    background: $white
    svg
      @include positionAbsolute(0, $left: 0)
      fill: $white

  .checkbox-label-wrapper
    @include fontSLH(16px, 24px)
    margin-left: 15px
    cursor: pointer
    user-select: none
    label
      cursor: pointer
      margin-left: 20px
      display: flex
    &:hover
      .checkmark
        border: 1px solid $green

  .checkbox-label
    line-height: 20px
