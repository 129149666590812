@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'

.change-password
  &__form-wrapper
    width: 25%
    max-height: 0
    transition: all 0.4s linear
    overflow: hidden

  &__save-button
    display: block
    font-size: 16px
    color: #fff
    line-height: 40px
    font-weight: bold
    background: $green
    border: none
    border-radius: 4px
    padding: 0 30px
    cursor: pointer
    text-transform: capitalize
    &:hover
      background: $green-hover


.form-active
  max-height: 300px
  margin-bottom: 32px
