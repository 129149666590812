@import "../../../../src/stylesheets/variables"
@import "../../../../src/stylesheets/mixins"


.basic-dropdown

  &.error .Dropdown-control
    border-color: $light-red

  &.disabled
    pointer-events: none
    cursor: default
    .Dropdown-arrow-wrapper
      display: none

  .Dropdown-control
    height: 40px
    border: 1px solid #E0E2E2
    border-radius: 4px
    font-size: 16px
    padding-left: 16px
    padding-right: 34px
    cursor: pointer

  .Dropdown-menu
    font-size: 16px
    max-height: 500px
    box-shadow: none
    //position: relative
    border: 1px solid #E0E2E2
    border-radius: 4px

  .Dropdown-arrow-wrapper
    @include positionAbsolute(6px, 10px)

  .Dropdown-placeholder
    @include truncate(100%)
    &.is-selected
      color: $default

  .required-icon
    @include positionAbsolute(8px, 0)
    @include fontSLH(12px, 14px)
    color: $red

  .error-tooltip
    @include fontSLH(12px, 20px)
    color: $light-red
    font-weight: 300

  .Dropdown-option
    color: $default
    &.is-selected, &:hover
      background: #f4f5f5
