@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.notification-popup
  right: 48px
  position: fixed
  width: calc(100% - 32px)
  border-radius: 4px
  background-color: $green-hover
  box-shadow: 0 1px 4px 0 rgba(49,60,63,0.25)
  z-index: 2000
  color: $white
  top: 80px
  opacity: 0
  transition: opacity 400ms ease-in
  height: 0
  overflow: hidden

  &.fadein
    opacity: 1
    height: auto
    padding: 24px 40px 24px 24px

  &.fadeout
    opacity: 0
    transition: opacity 400ms ease-in

  &.dark
    background-color: $default
    span
      color: $light-red

  &.error
    background-color: $light-red

  &.bottom-center
    left: calc(50vw - ((100% - 32px) / 2))
    bottom: 100px
    top: unset
    @media (min-width: 344px)
      left: calc(50vw - 156px)

  div
    @include fontSLH(16px, 19px)

  p
    line-height: 19px
    margin-bottom: 0
    b
      font-weight: 900

  &__close
    @include closeBtnRight(16px, 16px)
    svg
      pointer-events: none
      fill: $white

  @media (min-width: 344px)
    width: 312px
