@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.notes-modal
  padding: 24px
  width: 564px
  max-width: 564px

  .notes-wrapper
    margin-top: 0
    max-height: 80vh
    overflow: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none
