@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'


.contacts-step
  padding: 24px
  border-radius: 16px
  border: 1px solid #E6E6E6
  min-height: 500px

  &__create-contact-link
    padding: 9px 16px
    border: 1px solid $green
    font-weight: bold
    cursor: pointer
    border-radius: 4px
    user-select: none
    text-align: center
    &:hover
      text-decoration: underline

  &__tooltip
    p:first-child
      margin-bottom: 32px

  &__new-contact-form
    width: 366px
    border: 1px solid #EDEFF4
    border-radius: 4px
    background-color: rgba(246,246,246,0.52)
    padding: 24px
    .form-group
      margin-bottom: 16px !important
      &:last-child
        margin-bottom: 0 !important

  .Dropdown-placeholder
    @include truncate(100%)
