@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.simple-cms-modal
  width: 75%
  height: 75%
  padding: 0

  &__wrapper
    @include displayFlex(center, $flexDirection: column)
    width: 100%
    height: 100%
    padding: 20px

  &__close-btn
    @include positionAbsolute(-40px, -40px)
    @include square(40px)
    @include displayFlex(center, center)
    cursor: pointer
    background: $white
    border-radius: 50%

  iframe
    width: 100%
    height: 100%
