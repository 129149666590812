@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.assets-block
  min-width: 418px
  width: 418px

  &__gallery-wrapper
    width: 100%
    border-radius: 4px 4px 0px 0px
    overflow: hidden

  &__tabs-wrapper
    @include displayFlex(center, space-around)
    height: 72px
    border: 1px solid $bg-badge-rented-out
    border-radius: 0px 0px 4px 4px

  &__tab
    @include displayFlex(center, center, column)
    padding: 0 8px
    cursor: pointer
    &:hover, &.active
      span
        color: $green
      svg
        fill: $green
    svg
      @include square(24px)
    span
      @include fontSLH(12px, 16px)
      margin-top: 4px
      white-space: nowrap
    &.active
      border-bottom: 2px solid $green
      cursor: default
      pointer-events: none

  &__carousel-img-wrapper
    background-repeat: no-repeat
    background-size: auto 100%
    background-position: center

  &__placeholder
    @include square(100%)
    @include displayFlex(center, center)
    border: 1px $green solid
    border-radius: 4px
    padding: 32px

  .carousel
    .arrow-prev, .arrow-next, .carousel-status
      @include displayFlex(center, center)
      @include square(32px)
      position: absolute
      background: rgba(255, 255, 255, 0.8)
      border-radius: 4px
      z-index: 1

    .arrow-prev, .arrow-next
      cursor: pointer
      top: 158px

    .arrow-prev
      left: 16px

    .arrow-next, .carousel-status
      right: 16px

    .carousel-status
      @include font(11px, $default, 16px)
      top: 16px
      text-shadow: none

  &__frame-wrapper
    background: black
    iframe
      @include square(100%)
      border: 0
      margin: 0
      padding: 0

  &__frame-wrapper,
  &__tab,
  .map-container,
  .map-element
    height: 100%

  &__gallery-wrapper,
  &__carousel-img-wrapper
    height: 324px
