@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.sidebar
  background-color: #EDEFF4
  width: 272px
  min-width: 272px
  border-right: 1px solid #E4E7EB
  transition: 850ms

  & ~ .content-section
    width: calc(100% - 272px)
    .fixed-nav
      width: calc(100vw - 272px)
      left: 272px
      padding: 0 40px

  .sidebar-item__new-info-icon-wrapper
    padding: 2px 5px
    font-size: 10px
    line-height: 12px
    color: white
    font-weight: 500
    background: #ED3939
    border-radius: 8px
    margin-left: 16px
    width: fit-content
    justify-content: center

  &.inactive
    width: 74px
    min-width: 74px
    .sidebar-item
      margin: 8px 12px
      a
        svg
          margin-right: 0
      span
        display: none
      &__new-info-icon-wrapper
        margin-top: -16px
        margin-left: -6px

    .sidebar-banners-wrapper
      display: none

    & ~ .content-section
      width: calc(100% - 74px)
      .fixed-nav
        width: calc(100vw - 74px)
        left: 74px

  @media (max-width: 991px)
    width: 74px
    min-width: 74px
    .sidebar-item
      margin: 8px 12px
      a svg
        margin-right: 0
      span
        display: none
      &__new-info-icon-wrapper
        margin-top: -16px
        margin-left: -6px

    .sidebar-banners-wrapper
      display: none

    & ~ .content-section
      width: calc(100% - 74px)
      .fixed-nav
        width: calc(100vw - 74px)
        left: 74px

  &-items
    margin: 0
    padding: 0
    list-style-type: none
    width: 100%
    .sidebar-toggle
      position: relative
      cursor: pointer
      svg
        position: absolute
        background: #EDEFF4
        border-radius: 50%
        top: -35px
        right: -11px
  &-item
    margin: 8px 12px
    cursor: pointer
    align-items: center
    overflow: hidden
    a
      position: relative
      color: #000000
      font-size: 16px
      line-height: 20px
      font-family: "MuseoSans", Arial, Helvetica, sans-serif
      text-decoration: none
      width: 100%
      display: flex
      align-items: center
      padding: 11px 12px
      svg
        margin-right: 16px
        vertical-align: middle
      span
        vertical-align: middle
        transition: opacity 850ms ease-in
        white-space: nowrap

    &:hover
      background: #fff
      border-radius: 4px
      a
        font-weight: 900
        color: #000000

    .active
      background: #fff
      border-radius: 4px
      font-weight: 900
      color: #000000


    ul
      height: 0
      overflow: hidden
      -webkit-transition: height .3s ease
      background: #EDEFF4 !important
      margin: 0
      padding: 0
      list-style-type: none

  &-item-active
    .open
      display: block
    .closed
      display: none


  .horizontal-line
    margin: 24px 17px
    background-color: #CCCECF
    height: 1px
    border: none

  .nav-link-tooltip
    padding: 8px 16px !important

.sidebar-banners-wrapper
  background-color: #fff
  border-radius: 4px
  margin: 24px 17px
  padding: 24px 16px
  text-align: center
  img
    height: 48px
  a
    font-size: 16px
    color: #fff
    line-height: 24px
    font-weight: 900
    width: 100%
    text-transform: none
    margin-bottom: 0
    border: none
    white-space: normal
    cursor: pointer
    padding: 12px 24px
    text-align: center
    background-color: #ffa300
    border-radius: 4px
    display: inline-block
    touch-action: manipulation
    text-decoration: none
    &:hover
      text-decoration: none
      background-color: #d17c0a
      color: white
  &-text
    font-size: 16px
    line-height: 24px
    font-weight: 900
    margin: 20px 0 16px
  .banner-title
    font-size: 16px
    line-height: 24px
    font-weight: 900
    margin-bottom: 16px

  .banner-text
    @include fontSLH(16px, 21px)
    margin-bottom: 16px

  .get-partner-button
    background-color: $green
    &:hover
      background-color: $green-hover

  .facebook-banner-button
    @include font(14px, $white, 24px, bold)
    @include displayFlex(center, center)
    border-radius: 4px
    text-decoration: none
    text-transform: capitalize
    background: #1877F2
    height: 40px
    position: relative
    &:hover
      background: #1469D8
      text-decoration: none
    img
      @include positionAbsolute(0, $left: 5px)
      height: 40px
