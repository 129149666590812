@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.secondary-kinds-info-modal
  max-width: 436px
  padding: 24px

  h3
    @include fontSLH(20px, 28px)
    margin: 0 0 12px

  &__description
    margin-bottom: 32px

  &__pricing-list
    padding: 16px 16px 0
    border-radius: 8px
    background: #F4F5F8
    margin-bottom: 48px

  &__pricing-list-headline
    @include fontSLH(16px, 24px)
    margin-bottom: 16px
    font-weight: 900

  &__pricing-list-item
    height: 60px
    &:not(:last-child)
      border-bottom: 1px solid #D7DBE3

  &__pricing-list-item-price
    font-weight: 900

  &__button
    @include font(14px, $white, 24px, 900)
    @include displayFlex(center, center)
    height: 40px
    border-radius: 4px
    background: $green
    cursor: pointer
    width: 100%
    &:hover
      background: $green-hover
    &--cancel
      background: $white
      border: 1px solid $green
      color: $green
      margin-right: 19px
      &:hover
        background: $white
        border-color: $green-hover
        color: $green-hover

  &__pricing-list-item-category,
  &__pricing-list-item-price
    @include fontSLH(16px, 26px)
    max-width: 50%

  &__buttons,
  &__pricing-list-item
    @include displayFlex(center, space-between)

