@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.reject-agreement-modal
  @include displayFlex(center, $flexDirection: column)
  width: 568px
  max-width: 568px
  line-height: 24px
  padding: 40px

  &__subheader
    font-size: 20px
    margin-bottom: 8px

  &__client-info-wrapper
    margin-bottom: 16px
    width: 100%

  &__client-info-row
    @include displayFlex(flex-start)
    font-size: 16px
    margin-bottom: 8px

  &__client-info-label
    @include displayFlex($justifyContent: flex-end)
    width: 217px
    max-width: 217px
    font-weight: bold
    margin-right: 16px

  h3
    font-size: 24px
    margin-bottom: 24px

  p
    margin-bottom: 24px

  &__comment-wrap
    margin-bottom: 32px

  &__valid-comment
    color: #f22708
    margin-top: -5px
    font-weight: 300


  textarea
    width: 412px
    height: 112px
    resize: none
    padding: 8px
    border: 1px solid #BCBCBC
    border-radius: 4px

    &::placeholder
      @include font(14px, #BCBCBC)
    &:focus-visible
      outline: none

  textarea.error
    border: 1px solid #f22708


  .file-loader
    margin-bottom: 32px
