@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.reportdeal
  padding: 42px

.provider-table
  .check-button
    .select-option
      cursor: pointer
      .option-checkmark
        position: absolute
        left: 0
        width: 13px
        height: 13px
        background-color: #fff
        border-radius: 50%
        border: 1px solid #a2a7a8
        margin-top: 3px
        &:after
          @include beforeEmptySquare(7px, $bg-badge-new, 1px, $left: 1px)
          border-radius: 50%
          display: none

  input
    opacity: 0
    display: none
    &:checked
      ~ .select-option
        .option-checkmark
          border: 2px solid $green
          &:after
            display: block
