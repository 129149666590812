@import "../../../../src/stylesheets/variables"
@import "../../../../src/stylesheets/mixins"


.base-input
  margin-bottom: 16px
  display: flex
  flex-direction: column
  position: relative

  .floating-label
    @include positionAbsolute(15px, $left: 16px)
    font-size: 14px
    line-height: 24px
    color: $black
    transition: all ease-in-out 0.3s
    pointer-events: none
    &.active
      font-size: 12px
      transform: translate(0, -12px)
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      font-weight: 300

  input
    @include fullWidthSize(52px)
    @include font(14px, $black, 16px)
    font-weight: 500
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    padding: 0 16px
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    @media (min-width: $tablet)
      height: 54px
    @media (min-width: $desktop-md)
      height: 52px
    &:focus, &.filled
      padding-top: 15px
      & ~ .floating-label
        font-size: 12px
        transform: translate(0, -12px)
        font-family: MuseoSans, Helvetica, Arial, sans-serif
        font-weight: 300
    &.error
      border-color: $light-red
      margin-bottom: 8px
      & ~ .error-tooltip
        display: block
    &::placeholder
      color: $gull-grey-text
    &.sign-up-form__input-error
      border-color: $light-red
    input::-webkit-credentials-auto-fill-button
      visibility: hidden
      pointer-events: none
      position: absolute
      right: 0

  .error-tooltip
    @include fontSLH(12px, 20px)
    color: $light-red
    display: none
    font-weight: 300

  .password-visibility
    @include positionAbsolute(16px, 22px)
    fill: $gull-grey-text
    cursor: pointer
