@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.provider-header
  @include displayFlex(center)
  position: fixed
  height: 72px
  z-index: 1000
  width: 100%
  top: 0
  left: 0
  background: #fff
  border-bottom: 1px solid #E4E7EB
  box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)

  &__logo-wrapper
    display: inline-flex
    img
      height: 47px
      width: 136px
      margin: 0 24px

  &__buttons-wrapper
    @include displayFlex(center)
    margin-left: 158px

  &__button
    @include displayFlex(center, center)
    cursor: pointer
    margin-right: 24px
    height: 40px
    min-width: 160px
    padding: 0 16px
    border: 1px solid $orange
    border-radius: 4px
    text-decoration: none
    color: transparent
    &:hover
      border-color: $green
      color: transparent
      text-decoration: none
    span
      @include font(14px, #313C3F, 24px, 900)
      text-decoration: none
    &--orange
      background: $orange
      &:hover
        border-color: $orange-hover
        background: $orange-hover
      span
        color: $white
