// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.unresolved-agreement
  margin: 24px 48px 0
  border: 1px solid $bg-charcoal
  border-radius: 4px
  padding: 24px 24px 16px

  .provider-table-wrapper .provider-table
    overflow-x: hidden

  h3
    @include fontSLH(20px, 24px)
    margin-bottom: 8px

  &__explanation
    font-weight: 300
    margin-bottom: 24px

  &__reject-link
    @include fontSLH(16px, 24px)
    text-decoration: underline
    color: #FF0000
    cursor: pointer
    &:hover
      color: #8b0202

  .provider-table-wrapper
    margin-bottom: 0

  #unresolvedAgreementsTableWrapper
    padding-bottom: 0

    thead
      height: 24px
      border-bottom: 2px solid $bg-charcoal
      background: $white
      th
        background: $white
        line-height: 24px
        font-weight: 900
        padding: 0 32px 0 0
        &:last-child
          padding: 0

    tbody
      padding-top: 24px
      td
        @include fontSLH(16px, 24px)
        padding: 8px 32px 8px 0
        background: $white
        &:last-child
          padding-right: 24px
        a
          @include fontSLH(16px, 24px)
          text-decoration: underline
          color: $green
          &:hover
            color: $green-hover
      tr:first-child td
        padding-top: 24px
      tr:last-child td
        padding-bottom: 0
