@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'


.navigation-buttons
  @include displayFlex(center, space-between)
  @include positionAbsolute(false, 0, 48px, 0)
  width: 100%
  padding: 0 24px

  &__right-block, &__back-link
    @include displayFlex(center)

  &__back-link
    text-decoration: none
    margin-right: 24px
    cursor: pointer
    &:hover span
      text-decoration: underline
    span
      @include fontSLH(16px, 19px)
      font-weight: bold
      color: $default
    svg
      margin-right: 8px

  &__next, &__edit
    @include displayFlex(center, center)
    @include font(16px, $white, 19px, bold)
    height: 48px
    padding: 0 19px 0 24px
    background: $green
    border-radius: 4px
    cursor: pointer
    text-transform: capitalize
    &:hover
      background: $green-hover

    svg
      fill: $white
      margin-left: 8px

    &.submit
      padding-right: 24px
      background: $orange
      &:hover
        background: $orange-hover
      &.disabled
        background: $orange-hover !important
        pointer-events: none

    &.disabled
      background: $green-hover
      pointer-events: none

  &__edit
    margin-right: 24px
    padding-right: 24px
    pointer-events: none
    background: $white
    border: 1px solid $dark-borders
    color: $dark-borders
    &.active
      pointer-events: all
      color: $white
      background: $orange
      border-color: $orange
      &:hover, &.loading
        background: $orange-hover
        border-color: $orange-hover
