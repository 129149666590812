@import '../../../../../src/stylesheets/variables'
@import '../../../../../src/stylesheets/mixins'

.visuals

  &__block-wrapper:last-child
    .visuals-block
      margin-bottom: 0

  &__quality-score
    margin-bottom: 40px

  &__quality-score-banner
    border: 1px solid #5F42E8
    position: relative
    h3
      margin-bottom: 8px
      color: #5F42E8
      padding-right: 24px

  &__quality-score-img
    margin-right: 24px

  &__quality-score-description
    p
      margin-bottom: 0
    a
      margin-left: 5px

  &__quality-score-close
    @include closeBtnRight(13px, 13px)

  &__quality-score-trigger
    @include font(16px, #5F42E8, 24px, bold)
    svg
      fill: #5F42E8

  &__form-group
    padding: 32px

  &__input-headline
    @include font(18px, $default, 26px, bold)

  &__input-label
    @include fontSLH(16px, 24px)
    margin-bottom: 8px

  .form-group--visuals
    flex-direction: column

  &__form-banner
    width: 352px
    background-color: #FFFFFF

  &__banner-headline
    @include fontSLH(16px, 20px)
    font-weight: bold

  &__banner-content
    @include fontSLH(14px, 21px)

  &__banner-buttons
    align-items: center

  &__banner-read-more
    @include font(16px, #FFA300, 19px, bold)
    text-decoration: underline
    &:hover
      color: $orange-hover

  &__banner-buy-button
    @include displayFlex(center, center)
    @include font(16px, $white, 19px, bold)
    height: 40px
    padding: 0 24px
    background-color: #FFA300
    &:hover
      background: $orange-hover

  &__quality-score-banner,
  &__quality-score-trigger
    @include displayFlex(center)

  &__quality-score-banner,
  &__form-banner
    padding: 24px

  &__quality-score-banner,
  &__form-group
    background-color: #FAFCFF

  &__form-group,
  &__form-banner
    border: 1px solid #EDEFF4

  &__form-group,
  &__banner-buttons
    @include displayFlex(false, space-between)

  &__quality-score-trigger,
  &__banner-read-more,
  &__banner-buy-button
    cursor: pointer

  &__input-headline,
  &__banner-headline,
  &__banner-content
    margin-bottom: 16px

  &__quality-score-banner,
  &__form-group,
  &__form-banner,
  &__banner-buy-button
    border-radius: 4px

  &__save-button-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 40px

  &__back-button
    display: inline-flex
    text-decoration: none
    align-items: center
    &:hover span
      text-decoration: underline
    span
      @include fontSLH(16px, 19px)
      font-weight: bold
      color: $default
    svg
      margin-right: 8px

  &__save-button
    display: flex
    align-items: center
    justify-content: center
    font-size: 16px
    color: $white
    line-height: 19px
    font-weight: bold
    height: 48px
    padding: 0 24px
    background: $green
    border-radius: 4px
    cursor: pointer
    text-transform: capitalize
    text-decoration: none
    &:hover
      color: $white
      background: $green-hover

  .sectionScrollTarget
    position: relative
    top: -120px

  .score-action-tooltip
    margin-left: 0
