@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.delete-contact-forever-modal
  max-width: 476px
  min-width: 476px
  padding: 24px

  &__headline
    @include fontSLH(20px, 28px)
    font-weight: 900
    margin-bottom: 12px

  &__body
    @include fontSLH(16px, 24px)
    margin-bottom: 48px

  &__buttons-wrapper
    @include displayFlex(center, space-between)

  .provider-modal__button
    width: 100%
    height: 40px
    &.red
      margin-left: 16px

