@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.prm-block
  position: relative
  background: linear-gradient(to right, #77BC1F, #FFA300)
  border-radius: 8px
  padding: 1px
  -webkit-tap-highlight-color: transparent

  &__wrapper
    background: $white
    border-radius: 8px
    padding: 16px 24px

  // PARTNER BADGE =============================>
  .partner-badge
    @include displayFlex(center, space-between)

    &__content
      @include fontSLH(14px, 20px)
      margin-right: 24px
      display: flex
      flex-direction: column
      align-items: flex-start

    img
      margin-right: 16px

    a
      @include displayFlex(center, center)
      @include font(16px, $white, 19px, 900)
      min-height: 40px
      padding: 8px 24px
      min-width: 206px
      background: $green
      text-decoration: none
      border-radius: 4px
      cursor: pointer
      &:hover
        color: $white
        background: $green-hover

    .score-action-tooltip
      margin-left: 0
      display: inline-flex
      margin-bottom: 8px


