@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.quality-score-info-modal
  width: 659px
  padding: 24px

  .__react_component_tooltip.provider-tooltip
    width: 241px
    line-height: 18px
    color: $default

  &__headline
    @include fontSLH(20px, 28px)
    font-weight: 900
    letter-spacing: 0.2px
    margin-bottom: 24px

  &__content
    display: flex

  &__left
    width: 233px
    border-radius: 8px
    background: #F7F7F7
    padding: 16px 24px
    position: relative

  &__right
    margin-left: 24px

  &__img
    margin-bottom: -16px

  &__status-description
    @include font(14px, rgba(0, 0, 0, 0.80), 18px, 300)

  &__read-more
    @include font(12px, #A3A3A3, 18px, 500)
    display: inline-flex
    align-items: flex-start
    text-decoration: underline
    cursor: pointer
    position: absolute
    bottom: 24px
    width: calc(100% - 48px)
    svg
      fill: #A3A3A3
      margin-left: 8px
      margin-top: 2px
    &:hover
      color: $default
      svg
        fill: $default

  &__list-description
    @include fontSLH(12px, 18px)

  &__list-item
    @include displayFlex(center, space-between)
    height: 54px
    border-bottom: 1px solid #EAEAEA
    &:last-child
      border-bottom: none
      margin-bottom: -15px

  &__list-item-name
    @include displayFlex(center)
    .provider-tooltip
      display: flex
      opacity: 1 !important

  &__list-item-label
    @include fontSLH(16px, 24px)
    margin-right: 8px
    letter-spacing: -0.28px

  &__list-item-score
    @include displayFlex(center)
    @include fontSLH(14px, 16px)
    border-radius: 999px
    letter-spacing: -0.28px
    background: #FFF8EB
    height: 20px
    padding: 0 8px
    color: $orange
    font-weight: 900
    &.done
      height: 24px
      padding: 3px
      border: 1px solid #F4F6FA
      gap: 4px
      color: rgba(0, 0, 0, 0.60)
      background: transparent
      font-weight: 500
      svg
        fill: $white
