@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'


.progress-bar
  @include displayFlex(center, space-between)
  width: 100%
  margin-bottom: 40px
  position: relative
  @media (min-width: $desktop-md)
    margin-bottom: 62px

  &__background-line
    @include positionAbsolute(12px, 0)
    @include boxSize(100%, 1px)
    background-image: linear-gradient(to right, #757575 0%, #757575 50%, transparent 50%)
    background-size: 12px 1px
    background-repeat: repeat-x

  &__step-number-wrapper
    padding: 0 16px
    background: $white
    display: inline-flex
    margin: 0 auto 16px

  &__step-passed svg
    fill: $white

  &__step-link
    text-decoration: none
    color: $default
    &:hover
      text-decoration: none
      color: $default

  &__step-name
    @include fontSLH(16px, 20px)

  &__step-wrapper
    display: flex
    flex-direction: column
    text-align: center
    z-index: 1
    &.first .progress-bar__step-number-wrapper,
    &.last .progress-bar__step-number-wrapper
      margin: 0 0 16px

    &.first .progress-bar__step-number-wrapper
      padding-left: calc(50% - 12px)
      align-self: flex-start

    &.last .progress-bar__step-number-wrapper
      padding-right: calc(50% - 12px)
      align-self: flex-end

    &.active
      .progress-bar__step-number
        border-color: $green
        color: $green
        background: $white

      .progress-bar__step-name
        font-weight: bold

  &__step-number
    @include font(14px, #757575, 17px)
    @include minSize(24px, 24px)
    border: 1px solid #757575

    &.complete
      border: 1px solid $green

  &__background-line,
  &__step-number-wrapper
    z-index: 1

  &__step-passed,
  &__step-number
    @include square(24px)
    @include displayFlex(center, center)
    border-radius: 50%
    position: relative

  &__step-passed,
  &__step-number.complete
    background: $green
    color: $white

  .score-icon-wrapper
    @include positionAbsolute(-9px, -12px)
    margin-right: 0!important
    height: 20px !important

  .score-action-icon
    @include square(20px)
    @include displayFlex(center, center)
    border: 1px solid #F8F8F9
    border-radius: 50%

.report-deal-progress-bar
  margin-bottom: 32px


