@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'


.payment-modal
  min-width: 356px
  min-height: 488px

  &__headline
    margin-bottom: 16px

  &__form-wrapper
    min-height: 228px

  &__price-info
    margin-top: 32px

  &__price-info-row
    @include displayFlex(center, flex-end)
    @include fontSLH(16px, 24px)
    margin-bottom: 8px

  &__price-info-value
    font-weight: 900
    margin-left: 5px

  .loader
    border: 2px solid $green
    border-bottom-color: $green
    border-left-color: $white
    animation-duration: 0.7s
    left: calc(50% - 16px)
    top: calc(50% - 16px)

  &__submit-btn
    @include font(16px, $white, 19px, 900)
    @include displayFlex(center, center)
    margin-top: 32px
    padding: 8px
    min-height: 40px
    min-width: 112px
    background: $green
    border-radius: 4px
    cursor: pointer
    position: relative
    &:hover
      background: $green-hover
    &.inactive
      background: $green-hover
      pointer-events: none
    .loader
      border: 2px solid $white
      border-bottom-color: $white
      border-left-color: $green-hover
      animation-duration: 1s
      left: calc(50% - 12px)
      top: calc(50% - 12px)

  &__error-message
    @include fontSLH(12px, 15px)
    color: #EA4335
    font-size: 14px
    margin-bottom: 16px
    width: 292px

