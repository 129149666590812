@import '../../../src/stylesheets/mixins'


.clients

  &__status-dropdown
    max-width: 160px

  .searchable-dropdown
    min-width: 295px
    margin-right: 16px

  .locations-search-select
    z-index: 4
    &__menu-list
      max-height: 70vh

    &__single-value
      display: none

  .extra-lead-tooltip
    @include font(14px, #1665D8, 21px, 900)
    display: inline-flex
    cursor: pointer

  .searchable-dropdown,
  .locations-search-select
    max-width: 295px

  .table-page__filters-wrapper
    justify-content: space-between
