// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.help-page
  padding: 48px
  &__content
    li
      font-size: 16px
