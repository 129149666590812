// MOBILE_FIRST
@import '../../../../../src/stylesheets/variables'
@import '../../../../../src/stylesheets/mixins'

.fixed-nav
  @include displayFlex(flex-start)
  @include boxSize(100vw, 48px)
  position: fixed
  background: $white
  z-index: 998
  box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)
  left: 0
  transform: translateY(-100%)
  transition: transform ease .2s
  top: 72px
  &.active
    transform: translateY(0)

  &__links-wrapper
    list-style: none
    margin: 0
    padding: 0
    overflow-x: auto
    scroll-snap-type: x mandatory
    scroll-behavior: smooth
    -webkit-overflow-scrolling: touch

  &__link
    @include displayFlex(center)
    margin-right: 16px
    flex-shrink: 0
    height: 100%
    @media (min-width: $mobile)
      margin-right: 40px

    span
      @include font(14px, $default, false, bold)
      white-space: nowrap
      cursor: pointer
      text-transform: capitalize
    &.active span
      color: $green

  &__arrow-left,
  &__arrow-right
    @include positionAbsolute(0)
    align-items: center
    background: white
    opacity: 0.87
    width: 24px
    cursor: pointer
    svg
      @include svgSquare(34.4px, $dark-borders)
      position: absolute

  &__arrow-left
    left: 0
    svg
      left: -5px

  &__arrow-right
    right: 0
    svg
      right: -5px

  &__container
    height: 38px
    overflow: hidden
    position: relative

  &__links-wrapper,
  &__arrow-left,
  &__arrow-right
    display: flex
    height: 48px

@media (hover: hover)
  .fixed-nav__link:hover
    span
      color: $green
