@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.page-info-message
  @include displayFlex(center)
  padding: 24px 32px
  border: 1px solid #FF0000
  border-radius: 8px
  margin-bottom: 24px

  &__content
    @include font(14px, #BF0202, 17px)
    a
      color: #BF0202
      &:hover
        color: #8d0101

  svg
    margin-right: 32px
    min-width: 51px
