@import "../../../../src/stylesheets/variables"
@import "../../../../src/stylesheets/mixins"


.input-wrapper
  display: flex
  flex-direction: column
  position: relative

  input
    @include truncate(100%)
    @include fullWidthSize(40px)
    @include font(16px, $black, 24px)
    font-weight: 500
    border: 1px solid $borders
    background-color: $white
    border-radius: 4px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    padding: 0 16px
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
    &.error
      border-color: $light-red
      margin-bottom: 8px
      & ~ .error-tooltip
        display: block
    &::placeholder
      color: $dark-borders
    &:focus, &.filled
      padding-top: 0
    &.sign-up-form__input-error
      border-color: $light-red
    input::-webkit-credentials-auto-fill-button
      visibility: hidden
      pointer-events: none
      position: absolute
      right: 0
    &:disabled
      opacity: 0.5

  .error-tooltip
    @include fontSLH(12px, 20px)
    color: $light-red
    display: none
    font-weight: 300

  .password-visibility
    @include positionAbsolute(11px, 24px)
    fill: $gull-grey-text
    cursor: pointer

  .required-icon
    @include positionAbsolute(8px, 0)
    @include fontSLH(12px, 14px)
    color: $red



