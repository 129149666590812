@import "../../../../src/stylesheets/variables"
@import "../../../../src/stylesheets/mixins"


.searchable-dropdown

  &__trigger-area
    @include displayFlex(center)
    position: relative
    height: 40px
    border: 1px solid #E0E2E2
    border-radius: 4px
    font-size: 16px
    padding-left: 16px
    padding-right: 40px
    cursor: pointer
    svg
      @include positionAbsolute(6px, 10px)
      pointer-events: none
    &.disabled
      cursor: default
      svg
        fill: #E0E2E2
      .searchable-dropdown__trigger-label
        color: #E0E2E2
    &.error
      border-color: $light-red
      margin-bottom: 8px

  &__trigger-label
    @include truncate(100%)
    color: $default
    &.placeholder
      color: $dark-borders

  .hidden
    display: none

  .required-icon
    @include positionAbsolute(8px, 0)
    @include fontSLH(12px, 14px)
    color: $red

  input
    width: 100% !important

  .search-icon
    fill: #E0E2E2

  .error-tooltip
    @include fontSLH(12px, 20px)
    color: $light-red
    font-weight: 300

div:focus-visible
  outline: unset
