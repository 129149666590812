@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.anonymous-banner
  @include displayFlex(center)
  padding: 24px
  background: #FAFCFF
  border: 1px solid $green
  border-radius: 4px
  margin-left: 64px
  height: fit-content

  &__headline
    @include font(14px, $default, 17px, 900)
    margin-bottom: 8px

  img
    margin-right: 16px

  p
    font-size: 14px
    margin-bottom: 0
