@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.table-block
  &__title
    padding-bottom: 8px
    margin-bottom: 16px
    border-bottom: 1px solid #EDEDED

  .search-input-wrapper
    position: relative
    margin-bottom: 16px
    svg
      @include positionAbsolute(calc(50% - 8px), $left: 16px)

  .search-input
    width: 295px
    padding-left: 40px
    padding-right: 16px
    height: 40px
    border: 1px solid #E0E2E2
    border-radius: 4px
    font-size: 16px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none

  &__company-input-wrapper
    display: flex
    align-items: center
    position: relative
    .check-button
      .select-option
        margin-left: 20px
        @include fontSLH(14px, 21px)
        &:hover
          cursor: pointer
          color: $green
        .option-checkmark
          position: absolute
          left: 0
          width: 13px
          height: 13px
          background-color: #fff
          border-radius: 50%
          border: 1px solid #a2a7a8
          margin-top: 3px
          &:after
            @include beforeEmptySquare(7px, $bg-badge-new, 1px, $left: 1px)
            border-radius: 50%
            display: none

    .checkoption-input
      opacity: 0
      display: none
      &:checked
        ~ .select-option
          .option-checkmark
            border: 2px solid $green
            &:after
              display: block

  &__label
    margin-bottom: 0

  &__company-name-input
    width: 295px
    margin-left: 16px
    padding: 0 16px
    height: 32px
    border: 1px solid #E0E2E2
    border-radius: 4px
    font-size: 16px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none

  .navigation-buttons
    position: relative
    margin-top: 40px
    bottom: unset
    right: unset
    justify-content: flex-end







