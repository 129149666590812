@import '../../../../../src/stylesheets/variables'
@import '../../../../../src/stylesheets/mixins'

.file-input
  width: 100%

  &__drop-wrapper
    width: 150px
    height: 100px
    border: 1px solid #D8D8D8

  &__input
    display: none

  label
    @include font(16px, $green, 24px)
    svg
      fill: #D8D8D8

  span
    @include font(14px, $dark-borders, 18px, 300)
    display: block
    width: 126px
    text-align: center
    pointer-events: none

  &__preview-list
    display: flex
    flex-wrap: wrap

  &__dropzone
    padding-right: 16px
    padding-bottom: 16px

  &__rotate
    @include positionAbsolute(0, $left: 0)
    svg
      transform: rotate(133deg)

  &__preview-img
    @include boxSize(150px, 100px)
    padding: 8px
    background-color: $white
    cursor: all-scroll
    overflow: hidden
    &.error
      border-color: #EE7F74
      background-color: #FBF5F4

  &__name
    @include truncate(100%)
    @include font(12px, #A8ADAE, 22px, 300)

  &__error .provider-tooltip
    margin-right: 0 !important

  &__error-label
    @include font(12px, #EE7F74, 22px, 300)
    text-transform: capitalize
    cursor: default
    svg
      fill: #EE7F74
      margin-left: 5px

  &__delete
    @include positionAbsolute(0, 0)

  &__background
    @include positionAbsolute(0px, 0px)
    @include square(100%)
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    pointer-events: none
    height: 100%

  &__url-file-wrapper
    a
      @include font(16px, $default, 24px, 900)
      margin-right: 16px
      svg
        fill: $default
        margin-right: 8px
      &:hover
        color: $green
        svg
          fill: $green
      .lds-spinner
        margin-right: 8px

    svg
      cursor: pointer
      &:hover
        fill: #FF0000
      &.inactive
        fill: #FF0000
        pointer-events: none

  .lds-spinner.black
    color: $default
    div:after
      background: $default

  &__drop-wrapper,
  &__url-file-wrapper
    margin-bottom: 16px

  &__rotate,
  &__delete
    @include square(14px)
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12)
    background: $white

  &__rotate,
  &__error
    z-index: 1

  &__preview-img,
  &__background
    background-size: cover
    background-repeat: no-repeat
    background-position: center

  .file-drop-target,
  &__preview-img
    @include displayFlex(center, center, column)

  &__preview-list,
  &__background
    width: 100%

  label,
  .file-drop,
  .file-drop-target
    @include square(100%)

  label,
  &__rotate,
  &__delete
    @include displayFlex(center, center)
    cursor: pointer

  &__drop-wrapper,
  &__preview-list,
  &__preview-img
    position: relative

  &__error-label,
  &__url-file-wrapper a,
  &__url-file-wrapper
    @include displayFlex(center)
