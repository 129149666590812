@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.delete-note-modal,
.delete-contact-modal
  @include displayFlex(center, $flexDirection: column)
  max-width: 400px
  text-align: center

  img, .file-loader
    margin-bottom: 16px

  p.bold
    font-weight: 900

  .file-loader
    max-width: 300px
