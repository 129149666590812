// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.invoices
  padding: 94px 48px

  &__pay-link
    @include font(16px, $white, 19px, 900)
    display: inline-flex
    align-items: center
    justify-content: center
    min-height: 48px
    min-width: 112px
    margin-left: 8px
    padding: 8px
    background: #77BC1F
    border-radius: 4px
    cursor: pointer
    &:hover
      background: $green-hover

  .provider-table-wrapper .MuiTable-root .MuiTableHead-root
    height: 68px

  td
    position: relative
    background: $white !important
    &:nth-child(5) .skeleton-box-wrapper
      display: none
    a
      text-decoration: underline !important

  th
    background: #FCFCFC !important
    text-transform: capitalize
    &:nth-child(5)
      background: $white !important

  tr
    height: 64px

  &__column-bg
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
