@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.provider-footer
  //background: linear-gradient(180deg, #ECEEF2, #FFFFFF)
  width: 100%
  border-top: 1px solid #E4E7EB
  box-shadow: 0 0 14px rgba(222, 231, 231, 0.55466)

  &__logo
    img
      margin-top: -6px

  &__col
    padding-top: 26px
    p
      @include fontSLH(14px, 20px)

  &__info
    padding-top: 40px
    padding-bottom: 40px

  &__contacts
    p
      @include fontSLH(14px, 24px)

  &__bottom-links
    @include displayFlex(center)
    border-top: 1px solid #E0E2E2
    height: 56px
    .container
      @include displayFlex(center)
    a
      @include font(16px, $default, 24px)
      margin-right: 24px

  &__privacy-link
    @include displayFlex(center)
    img
      margin-right: 8px

  &__social-links
    float: right

  &__social-buttons-list
    display: flex
    justify-content: flex-start
    padding: 0
    margin-top: 24px

  &__social-link
    display: block
    float: left
    margin-right: 16px
    a
      height: 30px
      min-width: 30px
      display: block
      background-size: contain
    &--facebook
      background: url("../../../src/images/icons/facebook.svg")
    &--twitter
      background: url("../../../src/images/icons/twitter.svg")
    &--linkedin
      background: url("../../../src/images/icons/linkedin.svg")
    &--instagram
      background: url("../../../src/images/icons/instagram.svg")
    &:last-child
      margin-right: 0

  h3
    @include fontSLH(14px, 20px)
    margin-bottom: 0

  p
    margin-bottom: 0
