@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.tooltip-popup
  user-select: none
  position: relative

  &__tooltip
    @include positionAbsolute(50%, 50%)

  &__icon
    cursor: pointer
    &:hover svg
      fill: $green-hover
