@import '../../../src/stylesheets/mixins'

.contacts-create-modal
  max-width: 449px
  min-width: 449px
  outline: none
  .modal-close
    position: absolute
    right: 24px
    top: 24px
    cursor: pointer

  &__header
    @include font(18px, #000, 20px, 900)
    border-bottom: 1px solid #F6F6F6
    margin-bottom: 24px

  .create-new
    &__button-wrapper
      display: flex
      align-items: center
      margin-top: 24px

      .cancel-button
        background: $light-red
        margin-right: 40px
        &:hover
          background: $red

      .save-button
        background: $green
        &:hover
          background: $green-hover


