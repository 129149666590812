// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.leases

  &__status-dropdown
    max-width: 160px

  &__status-cell
    justify-content: space-between
    .cell-dropdown-wrapper
      margin: 0

  &__status-info
    color: $green-hover

  &__quality-score-table-header,
  &__status-cell
    white-space: nowrap

  &__quality-score-table-cell
    display: flex
    flex-direction: column

  &__quality-score-table-header,
  &__status-cell,
  &__status-cell,
  &__title,
  .table-actions-wrapper
    @include displayFlex(center)

  .table-action-link
    @include square(28px)
    @include displayFlex(center, center)
    border-radius: 4px
    border: 1px solid #EDEEEE
    margin-right: 8px
    background: $white
    &:hover
      background: rgba(118, 189, 29, 0.07)
      border-color: $green

  .table-actions-wrapper .__react_component_tooltip
    @include fontSLH(14px, 16px)
    padding: 8px 10px

  .table-page__filters-wrapper
    justify-content: space-between


