@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.deactivate-location-modal
  @include displayFlex(center, $flexDirection: column)
  width: 480px
  max-width: 480px !important
  text-align: center
  line-height: 24px

  &__headline
    @include fontSLH(20px, 28px)

  &__explanation
    margin-bottom: 24px

  &__location-address
    font-size: 18px

  &__report-deal-headline
    @include font(12px, $orange, 14px)

  &__deactivation-term-option-headline
    font-weight: 900

  &__deactivation-term-option-description
    @include fontSLH(14px, 22px)

  &__deactivation-term-option
    text-align: left

  &__button
    @include displayFlex(center, center)
    @include fontSLH(14px, 24px)
    height: 40px
    font-weight: 900
    border-radius: 4px
    width: 100%
    cursor: pointer
    &.close
      border: 1px solid $green
      color: $green
      svg
        fill: $green
        margin-right: 8px
      &:hover
        border-color: $green-hover
        color: $green-hover
        svg
          fill: $green-hover
      &.inactive
        pointer-events: none
        border-color: $green-hover
        color: $green-hover
        svg
          fill: $green-hover

    &.confirm
      background: $green
      color: $white
      &:hover
        background: $green-hover
      &.inactive
        pointer-events: none
        background: $green-hover

  &__buttons-wrapper
    @include displayFlex(center, space-between)
    width: 100%
    gap: 16px
    margin-top: 24px

  .radio-buttons
    display: inline-flex
    align-items: flex-start
    flex-direction: column
    &__button-wrapper
      margin-right: 0
      margin-bottom: 8px
      border-radius: 8px
      background: #F4F5F8
      padding: 18px
      width: 100%
      &:hover
        .radio-buttons__label
          font-size: 14px
        .radio-buttons__icon
          border-color: $green
      &.checked
        padding: 16px
        border: 2px solid $green
        .radio-buttons__icon
          border-color: $green
          span
            background: $green
        .radio-buttons__icon
          top: 14px
          left: 14px
      &:last-child
        margin-bottom: 16px
    &__icon
      top: 16px
      left: 16px
      background: #F4F5F8
    &__label
      font-size: 14px
      align-items: flex-start
    input
      margin-right: 20px

  textarea
    width: 100%
    height: 100px
    resize: none
    padding: 8px
    border: 1px solid #BCBCBC
    border-radius: 8px
    &::placeholder
      @include font(14px, #BCBCBC)
    &:focus-visible
      outline: none

  &__explanation,
  &__report-deal-question,
  &__deactivation-term-option-headline
    font-size: 16px

  &__location-address,
  .file-loader
    margin-bottom: 16px

  &__headline,
  &__explanation
    width: 100%
    text-align: left

  &__headline,
  &__location-address
    font-weight: 900


  img,
  &__headline,
  &__report-deal-headline,
  &__report-deal-question,
  &__deactivation-term-option-headline,
  .radio-buttons____button-wrapper
    margin-bottom: 8px
