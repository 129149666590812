@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'


.facts-step

  &__facilities-wrapper
    position: relative
    column-count: 4
    margin-bottom: 60px

  &__energy-rating-banner
    background-color: $border-light-grey
    margin-bottom: 72px
    padding: 24px
    img
      margin-right: 32px
    h3
      margin-bottom: 16px
    p
      margin-bottom: 0

  &__energy-rating-content
    margin-right: 59px

  &__energy-rating-button
    @include font(16px, $white, 24px, 900)
    justify-self: flex-end
    background: $green
    text-decoration: none
    padding: 12px 32px
    white-space: nowrap
    &:hover
      background: $green-hover
      color: $white
      text-decoration: none

  &__energy-rating-banner,
  &__energy-rating-button
    @include displayFlex(center)
    border-radius: 4px

  .location-form__section-wrapper
    margin-bottom: 16px
    padding-bottom: 0

  .form-title
    @include displayFlex(center)

  .sectionScrollTarget
    position: relative
    top: -120px
