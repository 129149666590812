@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.details-block
  &__title
    @include fontSLH(20px, 20px)
    margin-top: 24px
    padding-bottom: 8px
    margin-bottom: 24px
    border-bottom: 1px solid #EDEDED

  &__title-description
    font-weight: 300


  .navigation-buttons
    position: relative
    margin-top: 40px
    bottom: unset
    right: unset
    justify-content: flex-end

.deal-report
  &__line-wrapper
    display: flex
    align-items: center
    margin-bottom: 16px

  &__input-wrapper
    position: relative
    span
      position: absolute
      right: 22px
      line-height: 32px

  &__label
    margin-bottom: 0
    @include fontSLH(16px, 20px)
    flex: 0 0 227px

  &__label-description
    @include fontSLH(14px, 20px)
    font-weight: 300


  &__input
    width: 180px
    margin: 0 48px
    padding: 0 8px
    height: 32px
    border: 1px solid $borders
    border-radius: 4px
    font-size: 16px
    box-shadow: none !important
    outline: none !important
    -webkit-appearance: none
    &.error
      border-color: $light-red

  &__checkbox-wrapper
    display: flex
    align-items: center
    font-size: 16px
    position: relative
    cursor: pointer
    user-select: none
    .checkbox-label
      @include fontSLH(14px, 20px)
      color: $dark-borders
      cursor: pointer
      margin-left: 15px
      display: flex
      &:hover
        .checkmark
          border: 1px solid $green

    .deactivate-text
      @include fontSLH(16px, 20px)
      color: $dark

    input
      height: 16px
      width: 1px
      position: absolute
      left: 3px
      top: 5px
      border: none
      padding: 0
      &:checked ~ .checkmark
        background-color: $green
        border-color: $green

    .checkmark
      @include square(20px)
      @include positionAbsolute(0, $left: 0)
      position: absolute
      border: 1px solid $inactive
      border-radius: 2px
      background: $white
      svg
        @include positionAbsolute(0, $left: 0)
        fill: $white

  &__approve
    margin-top: 24px
    height: 40px
    width: 160px
    background: #77bc1f
    border: none
    border-radius: 4px
    color: #fff
    cursor: pointer
    font-size: 16px
    font-weight: bold
    float: right

  &__comment-title
    margin-top: 32px

  textarea
    height: 110px
    width: 100%
    border: 1px solid $borders
    padding: 10px

  .Dropdown-root
    .currency-wrapper
      display: flex
      align-items: center
      padding: 0 12px
      border-radius: 4px
      height: 32px
      border-color: $borders
      cursor: pointer
      svg
        margin-top: 5px


.validation-message-wrapper
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 8px 25px
  color: #fff
  border-radius: 4px
  background: #FF000066
  p
    margin-bottom: 0px

.ReactModalPortal
  .report-success
    .modal-wrapper
      display: flex
      align-items: center
      flex-direction: column
      padding: 32px
      &__title
        @include fontSLH(22px, 24px)
        margin-top: 16px
      &__thankyou-text
        margin-bottom: 24px
      &__review-text
        @include fontSLH(14px, 24px)
        margin-bottom: 8px

      .go-dashboard-button
        display: block
        margin-top: 40px
        padding: 8px 30px
        font-size: 16px
        color: #fff
        font-weight: bold
        background: #77bc1f
        border: none
        border-radius: 4px
        cursor: pointer
        text-decoration: none
        &:hover
          background: $green-hover


