@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.location-views-chart
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  &__top
    @include displayFlex(center, space-between)

  h3
    @include fontSLH(18px, 24px)
    margin: 0
  &__tooltip-wrap
    display: flex
    align-items: center
  &__days-switchers
    @include displayFlex(center)

  &__days-switcher
    @include displayFlex(center)
    @include fontSLH(10px, 12px)
    padding: 0 8px
    height: 16px
    border-radius: 8px
    cursor: pointer
    margin-left: 8px
    &.active
      cursor: default
    &.active, &:hover
      box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25)
    &:nth-child(1)
      border: 1px solid #2D66AF
      color: #2D66AF
      font
        color: #2D66AF
      &.active
        background: #2D66AF
        color: $white
        font
          color: $white
    &:nth-child(2)
      border: 1px solid #294096
      color: #294096
      font
        color: #294096
      &.active
        background: #294096
        color: $white
        font
          color: $white
    &:nth-child(3)
      border: 1px solid #563492
      color: #563492
      font
        color: #563492
      &.active
        background: #563492
        color: $white
        font
          color: $white

  .__react_component_tooltip.place-bottom
    margin-top: 25px

  .custom-tooltip__headline--green
    color: $green-hover


