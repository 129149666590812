// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.location-form-wrapper
  padding: 48px 24px 72px
  min-height: calc(100vh - 57px)
  position: relative
  max-width: 696px
  width: 100%
  margin: 0 auto
  @media (min-width: 992px)
    padding: 48px 24px 137px
    max-width: 1218px

  .save-changes-button
    @include font(16px, $white, 24px, 900)
    position: fixed
    bottom: 72px
    right: 24px
    display: inline-flex
    align-items: center
    height: 40px
    padding: 0 24px
    background: $orange
    z-index: 1
    &:hover
      background: $orange-hover

  &__tooltip
    background-color: #F5F5F5
    padding: 16px 24px
    p
      @include fontSLH(12px, 20px)

  &__tooltip-text
    width: 28%
    min-width: 301px

  &__tooltip-icon
    @include displayFlex(center)
    //i
    //  @include square(20px)
    //  @include font(12px, white, 20px)
    //  margin-right: 10px
    //  background: $green
    //  justify-content: center
    &:first-child
      margin-right: 40px

  .form-group
    @include displayFlex(flex-start)
    margin-bottom: 32px
    position: relative
    &--checkbox
      &:last-child
        margin-bottom: 0
    &--radio
      align-items: center
      .form-label
        line-height: 22px

    &__tooltip
      position: absolute
      max-width: 342px
      overflow: hidden
      padding: 24px
      background-image: repeating-linear-gradient(-3deg, #616161, #616161 6px, transparent 6px, transparent 12px, #616161 12px), repeating-linear-gradient(87deg, #616161, #616161 6px, transparent 6px, transparent 12px, #616161 12px), repeating-linear-gradient(177deg, #616161, #616161 6px, transparent 6px, transparent 12px, #616161 12px), repeating-linear-gradient(267deg, #616161, #616161 6px, transparent 6px, transparent 12px, #616161 12px)
      background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px
      background-position: 0 0, 0 0, 100% 0, 0 100%
      background-repeat: no-repeat
      p:last-child
        margin-bottom: 0
      &.relative
        position: relative
      a
        word-break: break-all

  .form-label
    justify-content: flex-end
    text-align: right
    padding-top: 8px
    padding-right: 24px !important
    span:last-child
      padding-right: 16px
    &--align-top
      padding-top: 0
    &--left
      text-align: left
      justify-content: flex-start

  input.alerts-border
    animation: blink 1s
    animation-iteration-count: 3

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  input[type=number]
    -moz-appearance: textfield

  .provider-tooltip
    display: block
    height: 24px
    margin-right: 16px

  .__react_component_tooltip
    font-family: "MuseoSans", sans-serif
    font-weight: 500
    max-width: 369px
    text-align: center
    padding: 16px
    white-space: normal
    height: auto
    &.show, &__body
      opacity: 1

    &__body
      @include font(16px, $default, 19px, 500)
      width: 246px
      border: 1px solid #E0E2E2
      background-color: #FFFFFF
      box-shadow: 0 2px 4px 0 rgba(110,110,110,0.26)
      padding: 16px
      &:before, &:after
        display: none
    svg
      //font-size: 22px
      fill: $green
      cursor: default

  .step-header
    @include fontSLH(20px, 24px)
    font-weight: 900

  .step-subheader
    margin-bottom: 8px

  .status-banner-wrapper
    @include displayFlex(center, center)
    position: fixed
    bottom: 100px
    z-index: 111
    height: 0

  .status-banner
    padding: 12px 16px
    border-radius: 12px
    background: #77BC1F
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 12px 15px -8px rgba(0, 0, 0, 0.15)
    width: 597px
    max-width: calc(100vw - 32px)
    color: $white
    position: relative

    &__icon
      @include square(28px)
      @include displayFlex(center, center)
      background: $white
      min-width: 28px
      border: 2px solid $green
      margin-right: 16px
      svg
        fill: $green

    &__close
      @include closeBtnRight(13px, 13px)
      fill: $white

    &__headline
      @include fontSLH(18px, 27px)
      margin: 0 0 4px
      letter-spacing: 0.18px

    &__subheader
      font-weight: 900

    p
      @include fontSLH(15px, 23px)

  .visuals-headline
    @include font(24px, $default, 30px, 900)

  .align-top
    align-self: flex-start

  .radio-buttons__button-wrapper
    margin-right: 32px

  &__tooltip,
  .form-group__tooltip,
  .save-changes-button,
  .__react_component_tooltip__body,
  .status-banner
    border-radius: 4px

  &__tooltip,
  .step-header,
  .form-title,
  .visuals-headline
    margin-bottom: 40px

  &__tooltip-text,
  .form-group--checkbox
    margin-bottom: 16px

  &__tooltip-icons,
  //&__tooltip-icon i,
  .form-label,
  .status-banner
    display: flex

  &__tooltip-icons,
  .form-group__tooltip,
  .status-banner
    p
      margin-bottom: 0

  //&__tooltip-icon i,
  .status-banner__icon
    border-radius: 50%

  .form-group__tooltip,
  .form-label,
  .__react_component_tooltip,
  .form-title,
  .status-banner__subheader
    @include fontSLH(16px, 24px)

  .form-label--bold,
  .form-title,
  .status-banner__headline,
  .status-banner__subheader
    font-weight: bold


@keyframes blink
  50%
    border-color: $green


.input-wrapper input,
.searchable-dropdown__trigger-area,
.Dropdown-control
  border: 1px solid #E6E8E8
  background: #F6F7F7

.location-form__section-wrapper
  padding: 24px
  border-radius: 16px
  border: 1px solid #E6E6E6
