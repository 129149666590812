@import '../../../../src/stylesheets/variables'
@import '../../../../src/stylesheets/mixins'


.description-step

  .sectionScrollTarget
    position: relative
    top: -120px

  h3
    @include displayFlex(center)
    margin-bottom: 16px

  &__generate-button-wrapper
    display: flex
    justify-content: flex-end
    .__react_component_tooltip
      border-color: $orange !important
      padding: 16px 24px !important
      max-width: 630px !important
      &:before
        border-bottom-color: $orange !important

  &__internal-details-tooltip
    max-width: calc(100% - 16px) !important
    p
      margin-bottom: 8px !important

  &__generate-button
    @include font(16px, $white, 20px, 900)
    display: inline-flex !important
    align-items: center
    justify-content: center
    padding: 6px 16px
    border-radius: 4px
    background: $orange
    cursor: pointer
    align-self: flex-end
    user-select: none
    min-height: 32px
    margin-right: 0 !important
    &:hover
      background: $orange-hover
    .loader
      position: relative
      margin-left: 8px
    &.disabled
      background: $orange-hover
      cursor: default

  &__locales-switcher
    display: flex
    flex-wrap: wrap

  &__locale-selector
    margin-right: 32px

  &__locale-icon
    @include boxSize(20px, 13px)
    margin-right: 8px

  &__required-icon
    @include positionAbsolute(8px, 0)

  &__description-error-tooltip
    @include fontSLH(12px, 20px)
    color: $light-red
    font-weight: 300

  &__preview-card
    padding: 16px 24px
    background: $border-light-grey
    margin-bottom: 32px

  &__preview-description
    margin-right: 87px
    width: 100%

  &__preview-locale-wrapper
    height: 24px

    .description-step__locale-name
      font-weight: 900
      white-space: nowrap
    i
      margin-right: 4px

  &__preview-headline
    @include truncate(10%)
    text-transform: uppercase
    width: 159px
    min-width: 159px

  &__edit-button
    align-self: center

  &__description-counter
    border-right: 1px solid #ccc
    border-left: 1px solid #ccc
    border-bottom: 1px solid #ccc
    border-bottom-right-radius: 4px
    border-bottom-left-radius: 4px
    height: 30px
    padding-left: 24px
    background-color: #fafafa

  &__description-buttons
    @include displayFlex(center, flex-end)
    padding-top: 24px

  &__button-clear,
  &__button-save
    @include font(16px, $white, 24px, bold)
    height: 32px
    padding: 0 16px

  &__button-clear
    color: $default
    &:hover
      text-decoration: underline

  &__button-save
    background: $green
    &:hover
      background: $green-hover
    &.disabled
      background: $light-green
      cursor: not-allowed

  &__formatter-wrapper.error
    .sun-editor
      border-bottom-color: #CCCCCC
    & ~ .description-step__description-counter,
    .sun-editor
      border-color: $light-red

  &__description-notice
    @include fontSLH(14px, 17px)
    margin-top: 16px
    strong
      font-weight: 500

  &__wrapper
    border-radius: 8px

  &__headline-text
    @include fontSLH(16px, 19px)

  &__go-edit
    background-color: #EDEFF4
    padding: 24px 32px
    margin-top: 32px

  &__go-edit-img img
    height: 24px
    margin-bottom: 16px

  &__go-edit-headline
    @include fontSLH(16px, 20px)
    font-weight: 900
    margin-bottom: 8px

  &__go-edit-description
    @include fontSLH(14px, 21px)

  &__go-edit-link
    @include font(16px, $white, 19px, 900)
    height: 40px
    background: $default
    text-decoration: none
    padding: 0 24px
    margin-left: 24px
    white-space: nowrap
    &:hover
      color: white
      text-decoration: none

  .form-group--lang
    .Dropdown-placeholder,
    .Dropdown-option
      @include displayFlex(center)

  .sun-editor
    border: 1px solid #CCCCCC
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    .se-toolbar
      border-top-left-radius: 4px
      border-top-right-radius: 4px
      height: 42px
      outline: none
      border-bottom: 1px solid #CCCCCC
    .se-resizing-bar
      display: none
    .se-wrapper-inner
      font-family: MuseoSans, sans-serif
      font-size: 16px
      line-height: 24px
      font-weight: 500
      resize: vertical
      color: $default
      min-height: 150px
      max-height: 550px
      height: auto !important
      p
        margin-bottom: 0
    .se-btn-module
      border: none
    .se-btn-tray
      display: flex
      justify-content: space-between
      padding-top: 0
    .se-btn:enabled.active
      color: $green

  &__preview-delete
    fill: $red

  &__internal-text,
  &__headline-text
    margin-bottom: 24px

  &__preview-card,
  &__go-edit-description
    @include displayFlex(flex-start)

  &__preview-locale-wrapper,
  &__preview-headline
    margin-right: 24px

  &__preview-description,
  &__preview-headline
    @include fontSLH(16px, 24px)

  &__preview-delete,
  &__button-clear
    margin-right: 16px

  &__description-notice,
  &__go-edit-description
    margin-bottom: 0

  &__edit-button svg,
  &__button-clear,
  &__button-save
    cursor: pointer

  &__locale-selector,
  &__button-clear,
  &__button-save
    @include displayFlex(center, center)

  &__required-icon,
  &__preview-locale-wrapper .description-step__locale-name,
  &__description-counter
    @include fontSLH(12px, 14px)

  &__formatter-wrapper.disabled,
  &__description-counter.disabled,
  &__title-wrapper.disabled input,
  .form-group--lang.disabled .Dropdown-root
    opacity: 0.5
    pointer-events: none

  &__preview-card,
  &__button-clear,
  &__button-save,
  &__go-edit,
  &__go-edit-link
    border-radius: 4px

  &__preview-locale-wrapper,
  &__edit-button,
  &__description-counter,
  &__go-edit-link,
  .sun-editor .se-toolbar
    @include displayFlex(center)


.save-description-modal

  .modal-wrapper
    padding: 40px 32px 32px

  .modal-close
    @include closeBtnRight(16px, 16px)

  .modal-buttons
    @include displayFlex(center, space-between)

  .modal-decline-button,
  .modal-save-button
    @include displayFlex(center, center)
    @include font(16px, $white, 24px, bold)
    height: 40px
    padding: 0 24px
    border-radius: 4px
    cursor: pointer

  .modal-save-button
    background: $green
    &:hover
      background: $green-hover
    &.disabled
      background: $light-green
      cursor: not-allowed

  .modal-decline-button
    background: $light-red
    margin-right: 24px
    &:hover
      background: $red
