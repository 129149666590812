@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.errors-page
  @include displayFlex(center, center, column)
  padding: 40px

  h3
    @include fontSLH(48px, 58px)
    margin-bottom: 16px

  img
    margin-bottom: 24px

  div
    @include fontSLH(24px, 29px)
    margin-bottom: 8px
    text-align: center

  a
    margin-left: 5px

  .page-not-found
    @include fontSLH(24px, 28px)
    font-weight: bold
    margin-bottom: 16px

  .return-dashboard-link
    @include fontSLH(24px, 28px)
    margin-top: 32px

