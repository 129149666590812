@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.provider-inactive
  padding: 48px

  h2
    @include fontSLH(24px, 24px)
    margin-bottom: 8px
    span
      color: #F00

  p
    margin-bottom: 16px
    font-size: 14px

  &__contacts
    line-height: 24px

  &__contacts-headline
    font-weight: 900

  &__contacts-line
    &.email
      margin-top: 24px
    &.hotline
      color: #F00
      margin-top: 8px

