@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.known-lead-location-modal
  @include displayFlex(center, $flexDirection: column)
  width: 612px
  max-width: 612px !important
  text-align: center
  line-height: 24px

  &__headline
    font-size: 24px
    font-weight: 900

  .provider-modal__button
    margin-top: 16px
    width: 240px
    max-width: 240px
    line-height: 21px
    &.green
      margin-right: 32px

  &__explanation
    font-size: 14px

  &__buttons-wrapper
    @include displayFlex(stretch)

  .file-loader,
  &__headline,
  &__explanation
    margin-bottom: 16px


