@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.photo-upload

  &__photo-place
    height: 80px
    width: 160px
    border: 1px solid #000
    margin-right: 40px
    border-radius: 4px

  .active-photo
    border: none

  &__provider-logo
    max-height: 100%
    max-width: 100%

  &__main-wrapper
    display: flex
    align-items: center
    margin-bottom: 40px


    .image-item
      display: flex
      align-items: center
      .logo-image
        margin-right: 40px
      .upload-image
        width: 160px
        height: 80px
        margin-right: 40px
        .image
          max-height: 100%
          max-width: 100%



    .upload-button
      margin-right: 25px
      background: none
      border: 1px solid $green
      padding: 8px 20px
      border-radius: 4px
      color: $green
      font-family: "MuseoSans", Arial, Helvetica, sans-serif
      font-size: 16px
      line-height: 24px
      font-weight: 900

    .remove-button
      background: none
      border: 1px solid #e0e2e2
      padding: 8px 20px
      border-radius: 4px
      color: #e0e2e2
      font-family: "MuseoSans", Arial, Helvetica, sans-serif
      font-size: 16px
      line-height: 24px
      font-weight: 900
      &:disabled
        cursor: default

    .remove-button-active
      border: 1px solid $red
      color: $red


.loading-spinner
  display: inline-block
  width: 15px
  height: 15px
  margin-right: 10px
  border: 2px solid $red
  border-radius: 50%
  border-top-color: #fff
  animation: spin 1s ease-in-out infinite
  -webkit-animation: spin 1s ease-in-out infinite

@keyframes spin
  to
    -webkit-transform: rotate(360deg)

@-webkit-keyframes spin
  to
    -webkit-transform: rotate(360deg)