@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.visuals-form
  display: flex
  flex-direction: column
  background: transparent !important
  &:not(.form-sent)
    transform: translate(-50%, -400px) !important

  &.form-sent
    .provider-modal__wrapper
      padding: 32px 64px
      width: auto
      height: 200px
      justify-content: center
      min-width: 500px
    .visuals-form__close-btn
      top: 24px
      right: 24px

  .provider-modal
    &__wrapper
      @include displayFlex(center, $flexDirection: column)
      background: $white
      padding: 48px 64px
      border-radius: 24px !important
      max-width: 1300px
      position: relative
      min-width: 1300px

    &__button
      min-width: 200px
      line-height: 24px

    &__close-btn
      display: none

  &__close-btn
    @include displayFlex(center, center)
    @include square(40px)
    @include positionAbsolute(48px, 48px)
    border-radius: 50%
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))
    border: 1px solid #E5E5E5
    cursor: pointer

  img
    margin-bottom: 24px

  h3
    @include fontSLH(20px, 26px)
    margin-bottom: 4px
    align-self: flex-start
    &.visuals-form__headline
      font-size: 24px
      align-self: center

  p
    margin-bottom: 24px
    &.visuals-form__subheader
      margin-bottom: 64px
      padding-top: 8px
      font-size: 16px
      color: rgba(0, 0, 0, 0.70)

  &__contact-form-header
    @include fontSLH(18px, 24px)
    font-weight: 900
    margin-bottom: 16px

  &__wrapper
    @include displayFlex(center)
    flex-direction: column
    max-width: 869px
    margin: auto

  &__example-sub
    @include fontSLH(12px, 24px)
    font-weight: 300
    margin: 8px 0
    width: 100%

  &__checkbox-wrapper
    height: 48px
    display: flex
    align-items: center
    border-bottom: 1px #D4D4D4 solid
    padding-left: 4px
    .form-group--checkbox, .checkbox-label-wrapper, .checkbox-label
      width: 100%
    .form-group--checkbox .checkbox-label-wrapper label
      margin-left: 13px
    &:last-child
      border-bottom: none

  &__checkbox-group,
  &__radio-group
    margin-bottom: 8px

  &__radio-group
    display: flex

  &__label
    @include displayFlex(flex-start, space-between)

  .radio-buttons
    flex-direction: column
    align-items: flex-start

    &__button-wrapper
      margin-right: 0
      height: 48px
      display: flex
      align-items: center !important
      border-bottom: 1px #D4D4D4 solid
      padding-left: 4px
      &:last-child
        border-bottom: none

    &__icon
      left: 4px
      top: 13px

    label, &__button-wrapper
      @include fontSLH(16px, 24px)
      width: 100%
      align-items: flex-start


  &__comment-headline
    @include fontSLH(15px, 24px)
    margin-bottom: 4px
    font-weight: 900
    margin-top: 24px

  textarea
    @include fontSLH(16px, 24px)
    border-radius: 8px
    border: 1px solid #EBEBEB
    width: 100%
    min-height: 88px
    color: $default
    max-width: 100%
    padding: 8px
    &:focus-visible
      outline: none

  &__contact-info
    border: 1px solid $borders
    padding: 24px 24px 16px
    width: 595px
    border-radius: 8px
    margin-top: -16px
    margin-bottom: 24px

  &__contact-info-row
    margin-bottom: 4px
    display: flex
    &--column
      flex-direction: column

  &__bottom-info
    text-align: center
    div
      margin-bottom: 16px
    p
      margin-bottom: 16px
      &:last-child
        margin-bottom: 0

  &__contact-link
    @include font(16px, $orange, 24px, 900)
    cursor: pointer
    margin-bottom: 32px
    display: inline-flex
    align-self: flex-start
    text-decoration: none
    &:hover
      color: $orange-hover
      text-decoration: underline

  &__contact-info-label
    @include font(15px, rgba(0, 0, 0, 0.60), 24px, 500)

  &__contact-info-value
    @include fontSLH(15px, 24px)

  &__contact-toggle-wrapper
    @include displayFlex(center)
    margin-top: 12px
    width: 100%
    border-top: 1px solid #D4D4D4
    padding-top: 16px
    p
      @include fontSLH(15px, 24px)
      margin-bottom: 0
      margin-right: 8px

  &__contact-toggle
    @include font(16px, $orange, 24px, 900)
    cursor: pointer
    display: inline-flex
    align-items: center
    text-decoration: none
    svg
      fill: $orange
      margin-left: 8px
    &:hover
      color: $orange-hover
      text-decoration: underline
      svg
        fill: $orange-hover

  &__order-summary-wrapper
    @include fontSLH(16px, 24px)
    padding: 24px
    width: 595px
    border-radius: 8px
    border: 0.5px solid rgba(255, 163, 0, 0.20)
    background: #FFFAF2
    margin-bottom: 48px
    .provider-modal__button
      width: 284px
      margin: 16px auto 0 !important
    .visuals-form__contact-form-header
      margin-bottom: 4px

  &__contact-form-subheader
    @include fontSLH(14px, 24px)
    color: rgba(0, 0, 0, 0.60)
    padding-bottom: 16px
    border-bottom: 1px solid rgba(0, 0, 0, 0.20)

  &__order-summary-option
    @include displayFlex(center, space-between)
    padding: 16px 0
    border-bottom: 1px solid rgba(0, 0, 0, 0.20)

  &__order-summary-option-value
    font-weight: 900

  &__gallery-wrapper
    width: 100%
    &.floor-plans .image-gallery-slide-wrapper
      border: 1px solid #DEDEDE
    &.floor-plans
      .image-gallery-slide
        padding: 32px
    &.images
      .image-gallery-slide
        overflow: hidden
        img
          min-width: 100%
          height: 100%
          width: unset
          object-fit: cover

  .image-gallery-slide
    height: 100%
    img
      max-height: 100% !important

  &__info-message-wrapper
    position: fixed
    bottom: 0
    left: 0
    width: 100vw
    height: 76px
    z-index: 99999
    visibility: hidden
    &.visible
      visibility: visible
    .notification-popup
      padding: 16px 24px
      position: sticky
      margin: 4px auto 0
      border-radius: 12px
      border: 1px solid rgba(255, 255, 255, 0.20)
      background-color: #535353
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.20), 0px 2px 2px -2px rgba(0, 0, 0, 0.10), 0px 9px 14px -8px rgba(0, 0, 0, 0.20)
      &__close
        display: none

  &__info-message-body
    @include displayFlex(center, space-between)
    @include fontSLH(15px, 22px)
    color: $white

  &__info-message-right
    @include displayFlex(center)
    font-weight: 900
    color: white
    text-decoration: none
    svg
      fill: white
      margin-left: 4px
    &:hover
      text-decoration: underline
      color: white

  .image-gallery-custom-right-nav,
  .image-gallery-custom-left-nav
    @include square(40px)
    @include positionAbsolute(calc(50% - 20px))
    @include displayFlex(center, center)
    border-radius: 50%
    background: rgba(233, 233, 233, 0.90)
    border: 1px solid #E5E5E5
    cursor: pointer

  .image-gallery-custom-right-nav
    right: -80px

  .image-gallery-custom-left-nav
    left: -80px

  .image-gallery-image,
  .image-gallery-thumbnail-image
    margin-bottom: 0

  .image-gallery-thumbnails
    padding: 16px 0 64px

  .image-gallery-thumbnail
    @include square(74px)
    border: 2px solid transparent
    padding: 3px

  .image-gallery-thumbnail .image-gallery-thumbnail-inner
    @include square(64px)
    overflow: hidden

  .image-gallery-thumbnail .image-gallery-thumbnail-image
    height: 100%
    width: auto

  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus
    border: 2px solid $default

  .image-gallery-slides
    min-height: 548px
    height: 548px
    min-width: 869px

  @media (hover: hover) and (pointer: fine)
    .image-gallery-thumbnail:hover
      border: 2px solid $default

  &__contact-info-value,
  &__price
    margin-left: 8px

  &__contact-info-label,
  &__price
    white-space: nowrap

  p
    @include fontSLH(14px, 24px)

  &__contact-info-value,
  &__price
    font-weight: 900

  .provider-modal__wrapper
    border-radius: 4px

  .provider-modal__button
    margin-bottom: 32px

  &__radio-group,
  &__label,
  &__checkbox-group,
  .radio-buttons,
  .provider-modal__wrapper
    width: 100%
