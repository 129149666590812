@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'


.main-provider-wrapper
  height: 100%
  display: flex

  .capitalize
    text-transform: capitalize

  .content-section
    //width: 100%
    display: flex
    flex-direction: column
    transition: 850ms
    //align-items: center

  .row
    margin-left: -8px !important
    margin-right: -8px !important

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10,
    .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4,
    .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11,
    .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4,
    .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11,
    .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4,
    .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11,
    .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4,
    .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11,
    .col-xl-12, .col-xl, .col-xl-auto
      padding-right: 8px !important
      padding-left: 8px !important


  .lds-spinner
    color: $green
    display: inline-block
    position: relative
    width: 0
    height: 0
    padding: 10px
    margin-bottom: 4px
    div
      transform-origin: 1px 0
      animation: lds-spinner 1.2s linear infinite
      &:after
        content: " "
        display: block
        position: absolute
        top: 6px
        left: 0
        width: 2px
        height: 5px
        border-radius: 20%
        background: $green
      &:nth-child(1)
        transform: rotate(0deg)
        animation-delay: -1.1s
      &:nth-child(2)
        transform: rotate(30deg)
        animation-delay: -1s
      &:nth-child(3)
        transform: rotate(60deg)
        animation-delay: -0.9s
      &:nth-child(4)
        transform: rotate(90deg)
        animation-delay: -0.8s
      &:nth-child(5)
        transform: rotate(120deg)
        animation-delay: -0.7s
      &:nth-child(6)
        transform: rotate(150deg)
        animation-delay: -0.6s
      &:nth-child(7)
        transform: rotate(180deg)
        animation-delay: -0.5s
      &:nth-child(8)
        transform: rotate(210deg)
        animation-delay: -0.4s
      &:nth-child(9)
        transform: rotate(240deg)
        animation-delay: -0.3s
      &:nth-child(10)
        transform: rotate(270deg)
        animation-delay: -0.2s
      &:nth-child(11)
        transform: rotate(300deg)
        animation-delay: -0.1s
      &:nth-child(12)
        transform: rotate(330deg)
        animation-delay: 0s
    &--red
      color: #FF0000
      div:after
        background: #FF0000

  @keyframes lds-spinner
    0%
      opacity: 1
    100%
      opacity: 0

  .create-new-button
    margin-left: 24px
    a
      display: inline-flex
      align-items: center
      font-size: 16px
      line-height: 20px
      font-weight: bold
      text-decoration: none
      border: 1px solid #77bc1f
      min-height: 36px
      border-radius: 23px
      padding: 7px 16px
      &:hover
        background: $green
        color: $white

  .__react_component_tooltip
    @include fontSLH(16px, 24px)
    font-family: "MuseoSans", sans-serif
    font-weight: 500
    max-width: 369px
    text-align: center
    padding: 16px
    white-space: normal
    height: auto
    &.show
      opacity: 1

  #score-tooltip, #view-tooltip
    text-align: left
    br
      display: none

.ReactModal__Body--open
  overflow: hidden
  height: 100vh

#common-icons-provider
  display: block !important
  height: 0
  width: 0
  overflow: hidden
