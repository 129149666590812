@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.clients-info
  padding: 32px
  &__main-title
    font-family: MuseoSans, sans-serif
    @include fontSLH(20px, 20px)
    border-bottom: 2px solid #EDEDED
    padding-bottom: 8px
    span
      font-weight: bold

  &__enquiries-title
    margin-top: 32px
    @include fontSLH(20px, 20px)
  &__save-button
    background-color: #ffa300
    height: 40px
    text-align: center
    font-size: 16px
    color: #fff
    line-height: 24px
    font-weight: 900
    border: none
    margin-bottom: 0
    cursor: pointer
    padding: 8px 40px
    border-radius: 4px
    display: inline-block
    &:hover
      text-decoration: none
      background-color: #d17c0a

  .extra-lead-tooltip
    @include font(14px, #1665D8, 21px, 900)
    display: inline-flex
    cursor: pointer