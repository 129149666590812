@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.tokenized-file-link-modal__link
  @include displayFlex(center, center)
  height: 40px
  border-radius: 3.5px
  border: 1px solid $green
  text-decoration: none
  padding: 0 16px
  margin-left: 8px
  font-size: 16px
  color: #77bc1f
  line-height: 24px
  cursor: pointer
  svg
    fill: $green
    margin-right: 8px
  &:hover
    color: $green-hover
    border-color: $green-hover
    svg
      fill: $green-hover

.loading-file-modal
  @include displayFlex(center, center, column)
  padding: 24px 64px 32px
  min-height: 407px
  width: 433px

  img, p
    margin-bottom: 16px

  &__headline
    @include font(22px, $default, 24px, 900)
    margin-bottom: 8px
    text-align: center

  a
    @include displayFlex(center, center)
    @include font(14px, $white, 24px, 900)
    padding: 8px 20px
    background: $orange
    border-radius: 4px
    text-decoration: none
    &:hover
      color: $white
      text-decoration: none
      background: $orange-hover

  .file-loader progress
    max-width: 200px
